import { Trans } from '@lingui/macro'
import { Currency } from '@uniswap/sdk-core'
import { Position } from '@uniswap/v3-sdk'
import classnames from 'classnames'
import RangeBadge from 'components/Badge/RangeBadge'
import { AutoColumn } from 'components/Column'
import DoubleCurrencyLogo from 'components/DoubleLogo'
import { Break } from 'components/earn/styled'
import CurrencyLogo from 'components/Logo/CurrencyLogo'
import RateToggle from 'components/RateToggle'
import { RowBetween, RowFixed } from 'components/Row'
import JSBI from 'jsbi'
import { ReactNode, useCallback, useState } from 'react'
import { Bound } from 'state/mint/v3/actions'
import { useTheme } from 'styled-components/macro'
import { ThemedText } from 'theme'
import { formatTickPrice } from 'utils/formatTickPrice'
import { unwrappedToken } from 'utils/unwrappedToken'

export const PositionPreview = ({
  position,
  title,
  inRange,
  baseCurrencyDefault,
  ticksAtLimit,
  backgrounds,
}: {
  position: Position
  title?: ReactNode
  inRange: boolean
  baseCurrencyDefault?: Currency
  ticksAtLimit: { [bound: string]: boolean | undefined }
  backgrounds: string
}) => {
  const theme = useTheme()

  const currency0 = unwrappedToken(position.pool.token0)
  const currency1 = unwrappedToken(position.pool.token1)

  // track which currency should be base
  const [baseCurrency, setBaseCurrency] = useState(
    baseCurrencyDefault
      ? baseCurrencyDefault === currency0
        ? currency0
        : baseCurrencyDefault === currency1
        ? currency1
        : currency0
      : currency0
  )

  const sorted = baseCurrency === currency0
  const quoteCurrency = sorted ? currency1 : currency0

  const price = sorted ? position.pool.priceOf(position.pool.token0) : position.pool.priceOf(position.pool.token1)

  const priceLower = sorted ? position.token0PriceLower : position.token0PriceUpper.invert()
  const priceUpper = sorted ? position.token0PriceUpper : position.token0PriceLower.invert()

  const handleRateChange = useCallback(() => {
    setBaseCurrency(quoteCurrency)
  }, [quoteCurrency])

  const removed = position?.liquidity && JSBI.equal(position?.liquidity, JSBI.BigInt(0))

  return (
    <AutoColumn gap="md" style={{ marginTop: '0.5rem' }}>
      <RowBetween>
        <RowFixed>
          <DoubleCurrencyLogo
            currency0={currency0 ?? undefined}
            currency1={currency1 ?? undefined}
            size={24}
            margin={true}
          />
          <ThemedText.DeprecatedLabel ml="10px" fontSize="24px">
            {currency0?.symbol} / {currency1?.symbol}
          </ThemedText.DeprecatedLabel>
        </RowFixed>
        <RangeBadge removed={removed} inRange={inRange} />
      </RowBetween>

      <div className={classnames(backgrounds, 'p-4 border border-section rounded-xl mt-2')}>
        <AutoColumn gap="md">
          <RowBetween>
            <RowFixed>
              <CurrencyLogo currency={currency0} />
              <ThemedText.DeprecatedLabel ml="8px">{currency0?.symbol}</ThemedText.DeprecatedLabel>
            </RowFixed>
            <RowFixed>
              <ThemedText.DeprecatedLabel mr="8px">{position.amount0.toSignificant(4)}</ThemedText.DeprecatedLabel>
            </RowFixed>
          </RowBetween>
          <RowBetween>
            <RowFixed>
              <CurrencyLogo currency={currency1} />
              <ThemedText.DeprecatedLabel ml="8px">{currency1?.symbol}</ThemedText.DeprecatedLabel>
            </RowFixed>
            <RowFixed>
              <ThemedText.DeprecatedLabel mr="8px">{position.amount1.toSignificant(4)}</ThemedText.DeprecatedLabel>
            </RowFixed>
          </RowBetween>
          <Break />
          <RowBetween>
            <ThemedText.DeprecatedLabel>
              <Trans>Fee Tier</Trans>
            </ThemedText.DeprecatedLabel>
            <ThemedText.DeprecatedLabel>
              <Trans>{position?.pool?.fee / 10000}%</Trans>
            </ThemedText.DeprecatedLabel>
          </RowBetween>
        </AutoColumn>
      </div>

      <AutoColumn gap="md">
        <RowBetween>
          {title ? <div className="font-bold">{title}</div> : <div />}
          <RateToggle
            currencyA={sorted ? currency0 : currency1}
            currencyB={sorted ? currency1 : currency0}
            handleRateToggle={handleRateChange}
          />
        </RowBetween>

        <div className="grid grid-cols-2 gap-4">
          <div className={classnames(backgrounds, 'p-4 border border-section rounded-xl mt-2')}>
            <AutoColumn gap="4px" justify="center">
              <div className="font-bold opacity-50 text-xs">
                <Trans>Min Price</Trans>
              </div>
              <div className="font-bold">
                {formatTickPrice({
                  price: priceLower,
                  atLimit: ticksAtLimit,
                  direction: Bound.LOWER,
                })}
              </div>
              <div className="font-bold text-xs">
                <Trans>
                  {quoteCurrency.symbol} per {baseCurrency.symbol}
                </Trans>
              </div>
              <div className="text-xs opacity-50 text-center">
                <Trans>Your position will be 100% composed of {baseCurrency?.symbol} at this price</Trans>
              </div>
            </AutoColumn>
          </div>
          <div className={classnames(backgrounds, 'p-4 border border-section rounded-xl mt-2')}>
            <AutoColumn gap="4px" justify="center">
              <div className="font-bold opacity-50 text-xs">
                <Trans>Max Price</Trans>
              </div>
              <div className="font-bold">
                {formatTickPrice({
                  price: priceUpper,
                  atLimit: ticksAtLimit,
                  direction: Bound.UPPER,
                })}
              </div>
              <div className="font-bold text-xs">
                <Trans>
                  {quoteCurrency.symbol} per {baseCurrency.symbol}
                </Trans>
              </div>
              <div className="text-xs opacity-50 text-center">
                <Trans>Your position will be 100% composed of {quoteCurrency?.symbol} at this price</Trans>
              </div>
            </AutoColumn>
          </div>
          <div className={classnames(backgrounds, 'p-4 border border-section rounded-xl mt-2 col-span-2')}>
            <AutoColumn gap="4px" justify="center">
              <div className="font-bold opacity-50 text-xs">
                <Trans>Current price</Trans>
              </div>
              <div className="font-bold">{`${price.toSignificant(5)} `}</div>
              <div className="font-bold text-xs">
                <Trans>
                  {quoteCurrency.symbol} per {baseCurrency.symbol}
                </Trans>
              </div>
            </AutoColumn>
          </div>
        </div>
      </AutoColumn>
    </AutoColumn>
  )
}
