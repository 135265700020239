import { Web3Provider } from '@ethersproject/providers'
import * as React from 'react'
import { useAccount, usePublicClient } from 'wagmi'

export function useEthersProvider({ chainId }: { chainId?: number } = {}) {
  const publicClient = usePublicClient({ chainId })
  const account = useAccount()

  const { chain, transport } = publicClient
  const network = {
    chainId: chain.id,
    name: chain.name,
    ensAddress: chain.contracts?.ensRegistry?.address,
  }
  const [provider, setProvider] = React.useState(new Web3Provider(transport, network))

  React.useEffect(() => {
    if (account?.connector) {
      account.connector
        .getProvider()
        .then((provider) => {
          setProvider(new Web3Provider(provider))
        })
        .catch((e) => {
          console.error(e)
        })
    } else {
      setProvider(new Web3Provider(transport, network))
    }
  }, [publicClient, account?.connector])

  return provider
}
