export const MANTLE_CHAIN_ID = 5000 as const

export const MANTLE_CONTRACTS = {
  ButterFactory: '0xEECa0a86431A7B42ca2Ee5F479832c3D4a4c2644',
  WMNT: '0x78c1b0C915c4FAA5FffA6CAbf0219DA63d7f4cb8',
  SwapRouter: '0xAe5c0a73bAE513fe5842DbcB8E32fc7E3e30DA57',
  NFTDescriptor: '0x5ec013Baea6e53837F293c38f819A26F93a59864',
  NonfungibleTokenPositionDescriptor: '0x078b232ff3d94DA081636CC45e39D88bd89F0105',
  NonfungiblePositionManager: '0x9aC371240b3dD63Fe5538cAC3f12cea657A3E9A1',
  QuoterV2: '0x8d8e0C6d1332E5cA51cE4b4370A54c680b014118',
  Quoter: '0x8973eAAD52703Fc0CBe4899d1ACD4f6706dfd816',
  V3Migrator: '0x81f2F03aD1E6eEc7Ff5FFbf138b4fc2fE810Ad02',
  ButterInterfaceMulticall: '0x943Fa56F1399D91260B03f1e056080BBD22654cD',
  Multicall2: '0xbe8818eAEdB69222C806C752114C1EbCBE576e9f',
  TickLens: '0xD43b91808a0B888a750C725FBA31404e3b6BD511',
  SwapRouter02: '0x13a48A555589Ad8223310FA778D5046904970b70',
  Permit2: '0x9b13cf0C98315b6e85E28630417050093A7086Aa',
  UnsupportedProtocol: '0x14D2b0a26eb0512693f29d9B80B0B15e937b8f65',
  UniversalRouter: '0x1344aA1D8dD14a775c74851821e0c76B373B9324',
} as const
