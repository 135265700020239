import './tw.css'

import { ConnectButton } from '@rainbow-me/rainbowkit'
import { sendAnalyticsEvent, Trace, user } from '@uniswap/analytics'
import { CustomUserProperties, getBrowser, SharedEventName } from '@uniswap/analytics-events'
import classnames from 'classnames'
import Loader from 'components/Icons/LoadingSpinner'
import TopLevelModals from 'components/TopLevelModals'
import { useFeatureFlagsIsLoaded } from 'featureFlags'
import { useAtom } from 'jotai'
import { useBag } from 'nft/hooks/useBag'
import { useButterPoints } from 'nft/utils/butterPoints'
import { Suspense, useEffect, useState } from 'react'
import { Navigate, Route, Routes, useLocation, useSearchParams } from 'react-router-dom'
import { shouldDisableNFTRoutesAtom } from 'state/application/atoms'
import { useRouterPreference } from 'state/user/hooks'
import styled from 'styled-components/macro'
import { useIsDarkMode } from 'theme/components/ThemeToggle'
import { flexRowNoWrap } from 'theme/styles'
import { Z_INDEX } from 'theme/zIndex'
import { getCurrentPageFromLocation } from 'utils/urlRoutes'
import { useAccount } from 'wagmi'
import { getCLS, getFCP, getFID, getLCP, Metric } from 'web-vitals'

import butter from '../assets/images/butter-blocks.svg'
import logo from '../assets/logo/logo_horizontal.svg'
import { useAnalyticsReporter } from '../components/analytics'
import ErrorBoundary from '../components/ErrorBoundary'
import { PageTabs } from '../components/NavBar'
import NavBar from '../components/NavBar'
import Popups from '../components/Popups'
import DarkModeQueryParamReader from '../theme/components/DarkModeQueryParamReader'
import AddLiquidity from './AddLiquidity'
import { RedirectDuplicateTokenIds } from './AddLiquidity/redirects'
import NotFound from './NotFound'
import Pool from './Pool'
import PositionPage from './Pool/PositionPage'
import RemoveLiquidityV3 from './RemoveLiquidity/V3'
import Swap from './Swap'
import { RedirectPathToSwapOnly } from './Swap/redirects'

// const TokenDetails = lazy(() => import('./TokenDetails'))
// const Vote = lazy(() => import('./Vote'))
// const NftExplore = lazy(() => import('nft/pages/explore'))
// const Collection = lazy(() => import('nft/pages/collection'))
// const Profile = lazy(() => import('nft/pages/profile/profile'))
// const Asset = lazy(() => import('nft/pages/asset/Asset'))

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  padding: ${({ theme }) => theme.navHeight}px 0px 5rem 0px;
  align-items: center;
  flex: 1;
`

const HeaderWrapper = styled.div`
  ${flexRowNoWrap};
  width: 100%;
  justify-content: space-between;
  position: fixed;
  top: 0;
  z-index: ${Z_INDEX.dropdown};
`

const GiftIcon = (props: { className?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth="1.5"
    stroke="currentColor"
    className={props.className}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M21 11.25v8.25a1.5 1.5 0 01-1.5 1.5H5.25a1.5 1.5 0 01-1.5-1.5v-8.25M12 4.875A2.625 2.625 0 109.375 7.5H12m0-2.625V7.5m0-2.625A2.625 2.625 0 1114.625 7.5H12m0 0V21m-8.625-9.75h18c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125h-18c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z"
    />
  </svg>
)

export default function App() {
  const isLoaded = useFeatureFlagsIsLoaded()
  const [shouldDisableNFTRoutes, setShouldDisableNFTRoutes] = useAtom(shouldDisableNFTRoutesAtom)

  const { pathname } = useLocation()
  const currentPage = getCurrentPageFromLocation(pathname)
  const isDarkMode = useIsDarkMode()
  const [routerPreference] = useRouterPreference()
  const [scrolledState, setScrolledState] = useState(false)

  useAnalyticsReporter()

  useEffect(() => {
    window.scrollTo(0, 0)
    setScrolledState(false)
  }, [pathname])

  const [searchParams] = useSearchParams()
  useEffect(() => {
    if (searchParams.get('disableNFTs') === 'true') {
      setShouldDisableNFTRoutes(true)
    } else if (searchParams.get('disableNFTs') === 'false') {
      setShouldDisableNFTRoutes(false)
    }
  }, [searchParams, setShouldDisableNFTRoutes])

  useEffect(() => {
    // User properties *must* be set before sending corresponding event properties,
    // so that the event contains the correct and up-to-date user properties.
    user.set(CustomUserProperties.USER_AGENT, navigator.userAgent)
    user.set(CustomUserProperties.BROWSER, getBrowser())
    user.set(CustomUserProperties.SCREEN_RESOLUTION_HEIGHT, window.screen.height)
    user.set(CustomUserProperties.SCREEN_RESOLUTION_WIDTH, window.screen.width)

    // Service Worker analytics
    const isServiceWorkerInstalled = Boolean(window.navigator.serviceWorker?.controller)
    const isServiceWorkerHit = Boolean((window as any).__isDocumentCached)
    const serviceWorkerProperty = isServiceWorkerInstalled ? (isServiceWorkerHit ? 'hit' : 'miss') : 'uninstalled'

    const pageLoadProperties = { service_worker: serviceWorkerProperty }
    sendAnalyticsEvent(SharedEventName.APP_LOADED, pageLoadProperties)
    const sendWebVital =
      (metric: string) =>
      ({ delta }: Metric) =>
        sendAnalyticsEvent(SharedEventName.WEB_VITALS, { ...pageLoadProperties, [metric]: delta })
    getCLS(sendWebVital('cumulative_layout_shift'))
    getFCP(sendWebVital('first_contentful_paint_ms'))
    getFID(sendWebVital('first_input_delay_ms'))
    getLCP(sendWebVital('largest_contentful_paint_ms'))
  }, [])

  useEffect(() => {
    user.set(CustomUserProperties.DARK_MODE, isDarkMode)
  }, [isDarkMode])

  useEffect(() => {
    user.set(CustomUserProperties.ROUTER_PREFERENCE, routerPreference)
  }, [routerPreference])

  useEffect(() => {
    const scrollListener = () => {
      setScrolledState(window.scrollY > 0)
    }
    window.addEventListener('scroll', scrollListener)
    return () => window.removeEventListener('scroll', scrollListener)
  }, [])

  const isBagExpanded = useBag((state) => state.bagExpanded)
  const isHeaderTransparent = !scrolledState && !isBagExpanded

  const account = useAccount()
  // const data = useButterPoints(account?.address)

  return (
    <ErrorBoundary>
      <DarkModeQueryParamReader />
      <Trace page={currentPage}>
        <HeaderWrapper
          className={classnames({
            'bg-base-100': !isHeaderTransparent,
            'border-b border-section': !isHeaderTransparent,
          })}
        >
          <NavBar blur={isHeaderTransparent} />
        </HeaderWrapper>
        <BodyWrapper>
          <Popups />
          {/* <Polling /> */}
          <TopLevelModals />
          <Suspense fallback={<Loader />}>
            {isLoaded ? (
              <Routes>
                <Route path="/" element={<Navigate to="/swap" replace />} />

                {/*<Route*/}
                {/*  path="vote/*"*/}
                {/*  element={*/}
                {/*    <Suspense fallback={<LazyLoadSpinner />}>*/}
                {/*      <Vote />*/}
                {/*    </Suspense>*/}
                {/*  }*/}
                {/*/>*/}
                {/*<Route path="create-proposal" element={<Navigate to="/vote/create-proposal" replace />} />*/}
                <Route path="send" element={<RedirectPathToSwapOnly />} />
                <Route path="swap" element={<Swap />} />

                {/*<Route path="pool/v2/find" element={<PoolFinder />} />*/}
                {/*<Route path="pool/v2" element={<PoolV2 />} />*/}
                <Route path="pool" element={<Pool />} />
                <Route path="pool/:tokenId" element={<PositionPage />} />

                {/*<Route path="pools/v2/find" element={<PoolFinder />} />*/}
                {/*<Route path="pools/v2" element={<PoolV2 />} />*/}
                <Route path="pools" element={<Pool />} />
                <Route path="pools/:tokenId" element={<PositionPage />} />

                {/*<Route path="add/v2" element={<RedirectDuplicateTokenIdsV2 />}>*/}
                {/*  <Route path=":currencyIdA" />*/}
                {/*  <Route path=":currencyIdA/:currencyIdB" />*/}
                {/*</Route>*/}
                <Route path="add" element={<RedirectDuplicateTokenIds />}>
                  {/* this is workaround since react-router-dom v6 doesn't support optional parameters any more */}
                  <Route path=":currencyIdA" />
                  <Route path=":currencyIdA/:currencyIdB" />
                  <Route path=":currencyIdA/:currencyIdB/:feeAmount" />
                </Route>

                <Route path="increase" element={<AddLiquidity />}>
                  <Route path=":currencyIdA" />
                  <Route path=":currencyIdA/:currencyIdB" />
                  <Route path=":currencyIdA/:currencyIdB/:feeAmount" />
                  <Route path=":currencyIdA/:currencyIdB/:feeAmount/:tokenId" />
                </Route>

                {/*<Route path="remove/v2/:currencyIdA/:currencyIdB" element={<RemoveLiquidity />} />*/}
                <Route path="remove/:tokenId" element={<RemoveLiquidityV3 />} />

                {/*<Route path="migrate/v2" element={<MigrateV2 />} />*/}
                {/*<Route path="migrate/v2/:address" element={<MigrateV2Pair />} />*/}

                {!shouldDisableNFTRoutes && (
                  <>
                    {/*<Route*/}
                    {/*  path="/nfts"*/}
                    {/*  element={*/}
                    {/*    <Suspense fallback={null}>*/}
                    {/*      <NftExplore />*/}
                    {/*    </Suspense>*/}
                    {/*  }*/}
                    {/*/>*/}

                    {/*<Route*/}
                    {/*  path="/nfts/asset/:contractAddress/:tokenId"*/}
                    {/*  element={*/}
                    {/*    <Suspense fallback={null}>*/}
                    {/*      <Asset />*/}
                    {/*    </Suspense>*/}
                    {/*  }*/}
                    {/*/>*/}

                    {/*<Route*/}
                    {/*  path="/nfts/profile"*/}
                    {/*  element={*/}
                    {/*    <Suspense fallback={null}>*/}
                    {/*      <Profile />*/}
                    {/*    </Suspense>*/}
                    {/*  }*/}
                    {/*/>*/}

                    {/*<Route*/}
                    {/*  path="/nfts/collection/:contractAddress"*/}
                    {/*  element={*/}
                    {/*    <Suspense fallback={null}>*/}
                    {/*      <Collection />*/}
                    {/*    </Suspense>*/}
                    {/*  }*/}
                    {/*/>*/}

                    {/*<Route*/}
                    {/*  path="/nfts/collection/:contractAddress/activity"*/}
                    {/*  element={*/}
                    {/*    <Suspense fallback={null}>*/}
                    {/*      <Collection />*/}
                    {/*    </Suspense>*/}
                    {/*  }*/}
                    {/*/>*/}
                  </>
                )}

                <Route path="*" element={<Navigate to="/not-found" replace />} />
                <Route path="/not-found" element={<NotFound />} />
              </Routes>
            ) : (
              <Loader />
            )}
          </Suspense>
        </BodyWrapper>
        <div className="border-t border-section">
          <div className="py-4 flex flex-col md:flex-row gap-6 container mx-auto text-xs px-4 md:px-0">
            <div>&copy; Butter {new Date().getFullYear()}. All rights reserved</div>
            <div className="flex gap-6">
              <div className="text-hint">
                <a target="_blank" className="hover:underline" href="https://butter.xyz/terms" rel="noreferrer">
                  Terms & Conditions
                </a>
              </div>
              <div className="text-hint">
                <a target="_blank" className="hover:underline" href="https://butter.xyz/privacy" rel="noreferrer">
                  Privacy Policy
                </a>
              </div>
            </div>
          </div>
          <div>
            <img src={butter} alt="logo" className="w-full" />
          </div>
        </div>
        <dialog id="nav_modal" className="modal">
          <div className="modal-box max-w-none w-screen bg-black absolute bottom-0">
            <div>
              <img src={logo} alt="logo" height="24" className="h-[1.5rem] lg:h-[2rem]" />
            </div>
            <div
              className={classnames('flex items-center gap-3 mt-6 justify-center')}
            >
              {/*{data?.now.points != null && (*/}
              {/*  <div className="flex items-center bg-primary text-primary-content h-10 rounded-xl px-2 select-none">*/}
              {/*    <GiftIcon className="w-4 h-4 mr-1" />*/}
              {/*    <a href="https://butter.xyz/app/rewards/v2" target="_blank" rel="noreferrer">*/}
              {/*      {Math.round(data.now.points)}*/}
              {/*      <span className="hidden lg:inline-block">&nbsp;Points</span>*/}
              {/*    </a>*/}
              {/*  </div>*/}
              {/*)}*/}
              <ConnectButton
                label="Connect"
                accountStatus={{ smallScreen: 'full', largeScreen: 'full' }}
                chainStatus={{ smallScreen: 'full', largeScreen: 'full' }}
              />
            </div>
            <div className="mt-6">
              <div className="flex flex-col">
                <PageTabs />
              </div>
            </div>
          </div>
          <form method="dialog" className="modal-backdrop backdrop-blur-sm">
            <button></button>
          </form>
        </dialog>
      </Trace>
    </ErrorBoundary>
  )
}
