import { Trans } from '@lingui/macro'
import { ConnectButton } from '@rainbow-me/rainbowkit'
import classnames from 'classnames'
import { useAccountDrawer } from 'components/AccountDrawer'
import { useIsNftPage } from 'hooks/useIsNftPage'
import { useIsPoolsPage } from 'hooks/useIsPoolsPage'
import { useProfilePageState } from 'nft/hooks'
import { ReactNode } from 'react'
import { NavLink, NavLinkProps, useLocation, useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { useAccount, useNetwork } from 'wagmi'

import logo from '../../assets/logo/logo_horizontal.svg'
import { useIsNavSearchInputVisible } from '../../nft/hooks/useIsNavSearchInputVisible'
import { useButterPoints } from '../../nft/utils/butterPoints'

// const Nav = styled.nav`
//   padding: ${({ theme }) => `${theme.navVerticalPad}px 12px`};
//   width: 100%;
//   height: ${({ theme }) => theme.navHeight}px;
//   z-index: 2;
// `

interface MenuItemProps {
  href: string
  id?: NavLinkProps['id']
  isActive?: boolean
  children: ReactNode
  dataTestId?: string
}

const MenuItem = ({ href, dataTestId, id, isActive, children }: MenuItemProps) => {
  return (
    <NavLink
      to={href}
      // className={isActive ? styles.activeMenuItem : styles.menuItem}
      id={id}
      data-testid={dataTestId}
      className={classnames('font-bold text-sm', {
        'opacity-50': !isActive,
        'hover:opacity-75': !isActive,
      })}
    >
      {children}
    </NavLink>
  )
}

export const PageTabs = () => {
  const { pathname } = useLocation()
  const connectedChainId = useNetwork().chain?.id

  const isPoolActive = useIsPoolsPage()

  return (
    <>
      <Link
        to="/swap"
        className={classnames('py-2 text-lg md:text-base font-bold', {
          'opacity-50': !pathname.startsWith('/swap'),
        })}
      >
        <Trans>Swap</Trans>
      </Link>
      <Link
        to="/pools"
        className={classnames('py-2 text-lg md:text-base font-bold', {
          'opacity-50': !isPoolActive,
        })}
      >
        <Trans>Pools</Trans>
      </Link>
      {/*<a*/}
      {/*  className={classnames('py-2 text-lg md:text-base font-bold opacity-50 flex items-center gap-2')}*/}
      {/*  href="https://butter.xyz/app/rewards/v2"*/}
      {/*  target="_blank"*/}
      {/*  rel="noreferrer"*/}
      {/*>*/}
      {/*  Rewards*/}
      {/*  <svg*/}
      {/*    xmlns="http://www.w3.org/2000/svg"*/}
      {/*    fill="none"*/}
      {/*    viewBox="0 0 24 24"*/}
      {/*    strokeWidth={1.5}*/}
      {/*    stroke="currentColor"*/}
      {/*    className="w-3 h-3 -ml-[3px]"*/}
      {/*  >*/}
      {/*    <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 19.5l15-15m0 0H8.25m11.25 0v11.25" />*/}
      {/*  </svg>*/}
      {/*</a>*/}
      <a
        className={classnames('py-2 text-lg md:text-base font-bold opacity-50 flex items-center gap-2')}
        href="https://stats.butter.xyz"
        target="_blank"
        rel="noreferrer"
      >
        Stats
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-3 h-3 -ml-[3px]"
        >
          <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 19.5l15-15m0 0H8.25m11.25 0v11.25" />
        </svg>
      </a>
    </>
  )
}

const GiftIcon = (props: { className?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth="1.5"
    stroke="currentColor"
    className={props.className}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M21 11.25v8.25a1.5 1.5 0 01-1.5 1.5H5.25a1.5 1.5 0 01-1.5-1.5v-8.25M12 4.875A2.625 2.625 0 109.375 7.5H12m0-2.625V7.5m0-2.625A2.625 2.625 0 1114.625 7.5H12m0 0V21m-8.625-9.75h18c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125h-18c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z"
    />
  </svg>
)

const Navbar = ({ blur }: { blur: boolean }) => {
  const isNftPage = useIsNftPage()
  const sellPageState = useProfilePageState((state) => state.state)
  const navigate = useNavigate()
  const isNavSearchInputVisible = useIsNavSearchInputVisible()

  const [accountDrawerOpen, toggleAccountDrawer] = useAccountDrawer()

  // const handleUniIconClick = useCallback(() => {
  //   if (accountDrawerOpen) {
  //     toggleAccountDrawer()
  //   }
  //   navigate({
  //     pathname: '/',
  //     search: '?intro=true',
  //   })
  // }, [accountDrawerOpen, navigate, toggleAccountDrawer])
  const account = useAccount()
  // const data = useButterPoints(account?.address)

  return (
    <>
      {/* {blur && <Blur />} */}
      <div className="w-full py-6 px-6 flex items-center justify-between gap-4">
        <div className="flex gap-1 lg:gap-4 items-center justify-start shrink-0">
          <NavLink to="/" className="mr-2 md:mr-6 hidden md:block">
            <img src={logo} alt="logo" height="24" className="h-[1.5rem] lg:h-[2rem]" />
          </NavLink>
          <div className="md:hidden">
            <div
              className="mr-2 flex items-center gap-4 cursor-pointer"
              onClick={() => (document.getElementById('nav_modal') as any).showModal()}
            >
              <img src={logo} alt="logo" height="24" className="h-[1.5rem] lg:h-[2rem]" />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-4 h-4 text-white"
              >
                <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
              </svg>
            </div>
          </div>
          <nav className="hidden md:flex gap-2 lg:gap-6">
            <PageTabs />
          </nav>
        </div>
        <div className="flex items-center gap-3">
          {/*{data?.now.points != null && (*/}
          {/*  <div className="flex items-center bg-primary text-primary-content h-10 rounded-xl px-2 select-none">*/}
          {/*    <GiftIcon className="w-4 h-4 mr-1" />*/}
          {/*    <a href="https://butter.xyz/app/rewards/v2" target="_blank" rel="noreferrer">*/}
          {/*      {Math.round(data.now.points)}*/}
          {/*      <span className="hidden lg:inline-block">&nbsp;Points</span>*/}
          {/*    </a>*/}
          {/*  </div>*/}
          {/*)}*/}
          <ConnectButton
            label="Connect"
            accountStatus={{ smallScreen: 'avatar', largeScreen: 'full' }}
            chainStatus={{ smallScreen: 'icon', largeScreen: 'full' }}
          />
        </div>
      </div>
    </>
  )
}

export default Navbar
