import { Trans } from '@lingui/macro'
import { TraceEvent } from '@uniswap/analytics'
import { BrowserEvent, InterfaceElementName, SwapEventName } from '@uniswap/analytics-events'
import { Currency, CurrencyAmount } from '@uniswap/sdk-core'
import { Pair } from '@uniswap/v2-sdk'
import classnames from 'classnames'
import { LoadingOpacityContainer, loadingOpacityMixin } from 'components/Loader/styled'
import { isSupportedChain } from 'constants/chains'
import { ReactNode, useCallback, useState } from 'react'
import { Lock } from 'react-feather'
import styled, { useTheme } from 'styled-components/macro'
import { flexColumnNoWrap, flexRowNoWrap } from 'theme/styles'
import { formatCurrencyAmount } from 'utils/formatCurrencyAmount'
import { useAccount, useNetwork } from 'wagmi'

import { ReactComponent as DropDown } from '../../assets/images/dropdown.svg'
import { useCurrencyBalance } from '../../state/connection/hooks'
import { ThemedText } from '../../theme'
import DoubleCurrencyLogo from '../DoubleLogo'
import CurrencyLogo from '../Logo/CurrencyLogo'
import { Input as NumericalInput } from '../NumericalInput'
import { RowBetween, RowFixed } from '../Row'
import CurrencySearchModal from '../SearchModal/CurrencySearchModal'
import { FiatValue } from './FiatValue'

const WalletIcon = ({ className }: { className?: string }) => (
  <svg className={className} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.1875 5.0625H3.9375C3.78832 5.0625 3.64524 5.00324 3.53975 4.89775C3.43426 4.79226 3.375 4.64918 3.375 4.5C3.375 4.35082 3.43426 4.20774 3.53975 4.10225C3.64524 3.99676 3.78832 3.9375 3.9375 3.9375H13.5C13.6492 3.9375 13.7923 3.87824 13.8977 3.77275C14.0032 3.66726 14.0625 3.52418 14.0625 3.375C14.0625 3.22582 14.0032 3.08274 13.8977 2.97725C13.7923 2.87176 13.6492 2.8125 13.5 2.8125H3.9375C3.48995 2.8125 3.06072 2.99029 2.74426 3.30676C2.42779 3.62322 2.25 4.05245 2.25 4.5V13.5C2.25 13.9476 2.42779 14.3768 2.74426 14.6932C3.06072 15.0097 3.48995 15.1875 3.9375 15.1875H15.1875C15.4859 15.1875 15.772 15.069 15.983 14.858C16.194 14.647 16.3125 14.3609 16.3125 14.0625V6.1875C16.3125 5.88913 16.194 5.60298 15.983 5.392C15.772 5.18103 15.4859 5.0625 15.1875 5.0625ZM12.6562 10.6875C12.4894 10.6875 12.3262 10.638 12.1875 10.5453C12.0487 10.4526 11.9406 10.3208 11.8767 10.1666C11.8129 10.0125 11.7962 9.84281 11.8287 9.67914C11.8613 9.51547 11.9416 9.36513 12.0596 9.24713C12.1776 9.12913 12.328 9.04877 12.4916 9.01621C12.6553 8.98366 12.825 9.00037 12.9791 9.06423C13.1333 9.12809 13.2651 9.23623 13.3578 9.37499C13.4505 9.51374 13.5 9.67687 13.5 9.84375C13.5 10.0675 13.4111 10.2821 13.2529 10.4404C13.0946 10.5986 12.88 10.6875 12.6562 10.6875Z"
      fill="currentColor"
    />
  </svg>
)

const InputPanel = styled.div<{ hideInput?: boolean }>`
  ${flexColumnNoWrap};
  position: relative;
  z-index: 1;
  transition: height 1s ease;
  will-change: height;
`
// width: ${({ hideInput }) => (hideInput ? '100%' : 'initial')};
// border-radius: ${({ hideInput }) => (hideInput ? '16px' : '20px')};
// background-color: ${({ theme, hideInput }) => (hideInput ? 'transparent' : theme.backgroundInteractive)};

const FixedContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 20px;
  background-color: ${({ theme }) => theme.backgroundInteractive};
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
`

// const Container = styled.div<{ hideInput: boolean; disabled: boolean }>`
//   border-radius: ${({ hideInput }) => (hideInput ? '16px' : '20px')};
//   border: 1px solid ${({ theme }) => theme.backgroundSurface};
//   background-color: ${({ theme }) => theme.deprecated_bg1};
//   width: ${({ hideInput }) => (hideInput ? '100%' : 'initial')};
//   ${({ theme, hideInput, disabled }) =>
//     !disabled &&
//     `
//     :focus,
//     :hover {
//       border: 1px solid ${hideInput ? ' transparent' : theme.deprecated_bg3};
//     }
//   `}
// `

// const CurrencySelect = styled(ButtonGray)<{
//   visible: boolean
//   selected: boolean
//   hideInput?: boolean
//   disabled?: boolean
// }>`
//   align-items: center;
//   background-color: ${({ selected, theme }) => (selected ? theme.backgroundInteractive : theme.accentAction)};
//   opacity: ${({ disabled }) => (!disabled ? 1 : 0.4)};
//   box-shadow: ${({ selected }) => (selected ? 'none' : '0px 6px 10px rgba(0, 0, 0, 0.075)')};
//   box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.075);
//   color: ${({ selected, theme }) => (selected ? theme.textPrimary : theme.white)};
//   cursor: pointer;
//   border-radius: 16px;
//   outline: none;
//   user-select: none;
//   border: none;
//   font-size: 24px;
//   font-weight: 500;
//   height: ${({ hideInput }) => (hideInput ? '2.8rem' : '2.4rem')};
//   width: ${({ hideInput }) => (hideInput ? '100%' : 'initial')};
//   padding: 0 8px;
//   justify-content: space-between;
//   margin-left: ${({ hideInput }) => (hideInput ? '0' : '12px')};
//   :focus,
//   :hover {
//     background-color: ${({ selected, theme }) => (selected ? theme.deprecated_bg3 : darken(0.05, theme.accentAction))};
//   }
//   visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
// `

const CurrencySelect = ({
  visible,
  selected,
  hideInput,
  disabled,
  className,
  onClick,
  children,
}: {
  visible: boolean
  selected: boolean
  hideInput?: boolean
  disabled?: boolean
  children: ReactNode
  className?: string
  onClick: () => void
}) => {
  return (
    <button
      className={classnames(
        'rounded-full font-bold',
        {
          'opacity-50': disabled,
          hidden: !visible,
          'ml-3': !hideInput,
        },
        className
      )}
      onClick={onClick}
    >
      {children}
    </button>
  )
}

const InputRow = styled.div<{ selected: boolean }>`
  ${flexRowNoWrap};
  align-items: center;
  justify-content: space-between;
  padding: ${({ selected }) => (selected ? ' 1rem 1rem 0.75rem 1rem' : '1rem 1rem 1rem 1rem')};
`

const LabelRow = styled.div`
  ${flexRowNoWrap};
  align-items: center;
  color: ${({ theme }) => theme.textPrimary};
  font-size: 0.75rem;
  line-height: 1rem;
  padding: 0 1rem 1rem;
  span:hover {
    cursor: pointer;
    color: ${({ theme }) => theme.textSecondary};
  }
`

const FiatRow = styled(LabelRow)`
  justify-content: flex-end;
  padding: 0px 1rem 0.75rem;
  height: 32px;
`

const Aligner = styled.span`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`

const StyledDropDown = styled(DropDown)<{ selected: boolean }>`
  margin: 0 0.25rem 0 0.35rem;
  height: 6px;

  path {
    stroke: ${({ selected, theme }) => (selected ? theme.textPrimary : theme.white)};
    stroke-width: 1.5px;
  }
`

const StyledBalanceMax = styled.button<{ disabled?: boolean }>`
  background-color: transparent;
  background-color: ${({ theme }) => theme.deprecated_primary5};
  border: none;
  border-radius: 12px;
  color: ${({ theme }) => theme.accentAction};
  cursor: pointer;
  font-size: 11px;
  font-weight: 500;
  margin-left: 0.25rem;
  opacity: ${({ disabled }) => (!disabled ? 1 : 0.4)};
  padding: 4px 6px;
  pointer-events: ${({ disabled }) => (!disabled ? 'initial' : 'none')};

  :hover {
    opacity: ${({ disabled }) => (!disabled ? 0.8 : 0.4)};
  }

  :focus {
    outline: none;
  }
`

const StyledNumericalInput = styled(NumericalInput)<{ $loading: boolean }>`
  ${loadingOpacityMixin};
  text-align: left;
`

interface CurrencyInputPanelProps {
  value: string
  onUserInput: (value: string) => void
  onMax?: () => void
  showMaxButton: boolean
  label?: ReactNode
  onCurrencySelect?: (currency: Currency) => void
  currency?: Currency | null
  hideBalance?: boolean
  pair?: Pair | null
  hideInput?: boolean
  otherCurrency?: Currency | null
  fiatValue?: { data?: number; isLoading: boolean }
  id: string
  showCommonBases?: boolean
  showCurrencyAmount?: boolean
  disableNonToken?: boolean
  renderBalance?: (amount: CurrencyAmount<Currency>) => ReactNode
  locked?: boolean
  loading?: boolean
}

export default function CurrencyInputPanel({
  value,
  onUserInput,
  onMax,
  showMaxButton,
  onCurrencySelect,
  currency,
  otherCurrency,
  id,
  showCommonBases,
  showCurrencyAmount,
  disableNonToken,
  renderBalance,
  fiatValue,
  hideBalance = false,
  pair = null, // used for double token logo
  hideInput = false,
  locked = false,
  loading = false,
  ...rest
}: CurrencyInputPanelProps) {
  const [modalOpen, setModalOpen] = useState(false)
  const account = useAccount()?.address
  const chainId = useNetwork().chain?.id
  const selectedCurrencyBalance = useCurrencyBalance(account ?? undefined, currency ?? undefined)
  const theme = useTheme()

  const handleDismissSearch = useCallback(() => {
    setModalOpen(false)
  }, [setModalOpen])

  const chainAllowed = isSupportedChain(chainId)

  return (
    <div
      className={classnames('z-[1] relative rounded-xl bg-section border-section py-1 px-2', {
        'rounded-xl': !hideInput,
        'rounded-full': hideInput,
      })}
      id={id}
      {...rest}
    >
      {locked && (
        <div className="w-full h-full absolute z-[2] bg-base-100 border border-section rounded-xl">
          <div className="flex flex-col justify-center items-center w-full h-full bg-section rounded-xl gap-2">
            <Lock />
            <ThemedText.DeprecatedLabel fontSize="12px" textAlign="center" padding="0 12px">
              <Trans>The market price is outside your specified price range. Single-asset deposit only.</Trans>
            </ThemedText.DeprecatedLabel>
          </div>
        </div>
      )}
      <div>
        <InputRow style={hideInput ? { padding: '0' } : {}} selected={!onCurrencySelect}>
          {!hideInput && (
            <StyledNumericalInput
              className="token-amount-input"
              value={value}
              onUserInput={onUserInput}
              disabled={!chainAllowed}
              $loading={loading}
            />
          )}

          <CurrencySelect
            disabled={!chainAllowed}
            visible={currency !== undefined}
            selected={!!currency}
            hideInput={hideInput}
            className="open-currency-select-button"
            onClick={() => {
              if (onCurrencySelect) {
                setModalOpen(true)
              }
            }}
          >
            <Aligner>
              <RowFixed>
                {pair ? (
                  <span style={{ marginRight: '0.5rem' }}>
                    <DoubleCurrencyLogo currency0={pair.token0} currency1={pair.token1} size={24} margin={true} />
                  </span>
                ) : currency ? (
                  <CurrencyLogo style={{ marginRight: '0.5rem' }} currency={currency} size="24px" />
                ) : null}
                {pair ? (
                  <div>
                    {pair?.token0.symbol}:{pair?.token1.symbol}
                  </div>
                ) : (
                  <div
                    className={classnames({
                      'pl-2': !currency,
                    })}
                  >
                    {(currency && currency.symbol && currency.symbol.length > 20
                      ? currency.symbol.slice(0, 4) +
                        '...' +
                        currency.symbol.slice(currency.symbol.length - 5, currency.symbol.length)
                      : currency?.symbol) || <Trans>Select a token</Trans>}
                  </div>
                )}
              </RowFixed>
              {onCurrencySelect && <StyledDropDown className="opacity-50" selected={!!currency} />}
            </Aligner>
          </CurrencySelect>
        </InputRow>
        {!hideInput && !hideBalance && currency && (
          <FiatRow>
            <RowBetween>
              <LoadingOpacityContainer $loading={loading}>
                {fiatValue && <FiatValue fiatValue={fiatValue} />}
              </LoadingOpacityContainer>
              {account ? (
                <RowFixed style={{ height: '17px' }}>
                  <div className="opacity-50 text-xs curosr-pointer" onClick={onMax}>
                    {!hideBalance && currency && selectedCurrencyBalance ? (
                      renderBalance ? (
                        renderBalance(selectedCurrencyBalance)
                      ) : (
                        <div className="flex items-center gap-2">
                          <WalletIcon /> {formatCurrencyAmount(selectedCurrencyBalance, 4)}
                        </div>
                      )
                    ) : null}
                  </div>
                  {showMaxButton && selectedCurrencyBalance ? (
                    <TraceEvent
                      events={[BrowserEvent.onClick]}
                      name={SwapEventName.SWAP_MAX_TOKEN_AMOUNT_SELECTED}
                      element={InterfaceElementName.MAX_TOKEN_AMOUNT_BUTTON}
                    >
                      <button className="text-primary ml-2 font-bold cursor-pointer" onClick={onMax}>
                        <Trans>MAX</Trans>
                      </button>
                    </TraceEvent>
                  ) : null}
                </RowFixed>
              ) : (
                <span />
              )}
            </RowBetween>
          </FiatRow>
        )}
      </div>
      {onCurrencySelect && (
        <CurrencySearchModal
          isOpen={modalOpen}
          onDismiss={handleDismissSearch}
          onCurrencySelect={onCurrencySelect}
          selectedCurrency={currency}
          otherSelectedCurrency={otherCurrency}
          showCommonBases={showCommonBases}
          showCurrencyAmount={showCurrencyAmount}
          disableNonToken={disableNonToken}
        />
      )}
    </div>
  )
}
