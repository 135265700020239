import classnames from 'classnames'
import { WARNING_LEVEL } from 'constants/tokenSafety'
import { ReactNode } from 'react'
import { AlertTriangle, Slash } from 'react-feather'
import { Text } from 'rebass'
import styled from 'styled-components/macro'

const Title = styled(Text)`
  margin-right: 5px;
  font-weight: 700;
  font-size: 12px;
`

type TokenWarningLabelProps = {
  level: WARNING_LEVEL
  canProceed: boolean
  children: ReactNode
}
export default function TokenSafetyLabel({ level, canProceed, children }: TokenWarningLabelProps) {
  return (
    <div
      className={classnames('p-1 text-xs rounded-xl inline-flex items-center', {
        'text-warning-content': level === WARNING_LEVEL.MEDIUM,
        'bg-warning': level === WARNING_LEVEL.MEDIUM,
        'text-error-content': level === WARNING_LEVEL.BLOCKED || level === WARNING_LEVEL.UNKNOWN,
        'bg-error': level === WARNING_LEVEL.BLOCKED || level === WARNING_LEVEL.UNKNOWN,
      })}
    >
      <Title marginRight="5px">{children}</Title>
      {canProceed ? <AlertTriangle strokeWidth={2.5} size="14px" /> : <Slash strokeWidth={2.5} size="14px" />}
    </div>
  )
}
