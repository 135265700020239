export const MANTLE_TESTNET_CHAIN_ID = 5003 as const

export const MANTLE_TESTNET_CONTRACTS = {
  ButterFactory: '0x2446E43392152E134ac4D451740fd6C4BCb86612',
  WMNT: '0xEa12Be2389c2254bAaD383c6eD1fa1e15202b52A',
  SwapRouter: '0x78aB628638D4A92Bb4614185F580EC63A0De5dC4',
  NFTDescriptor: '0xEaAaE5A41bDB94d0cFc7C55C3589AA17604ab0f1',
  NonfungibleTokenPositionDescriptor: '0x8D3D204e34a456E5d84814a1F2697AD834BBe065',
  NonfungiblePositionManager: '0xEA0a988E5AA3C403b2134eD1c3DB9970D01D7464',
  QuoterV2: '0x9f7D2198b64816A19F14d8d18b9265399b22283D',
  Quoter: '0x6797Bd3ceBa7a93D92B38c2Bd2875Ce73138dEd4',
  V3Migrator: '0xdc73414F5100916E734fc562F47d081d8144E56F',
  ButterInterfaceMulticall: '0x5C3B7A8b8f7A6Eff5a469A066c398AfC1825eB69',
  Multicall2: '0x577EE493c0DBD6Ee055F67a2BBfa79634cfB7e49',
  TickLens: '0x61267614E5A2af3f85a1398771c98C6d2e696A32',
  SwapRouter02: '0x960dec4df02b10a2EEDce1326C50565166715076',
  Permit2: '0xb00FcB9DC31b34B8Fb3D8d7376d7E248b932a4C3',
  UnsupportedProtocol: '0x3B7A52C33134b9B9d89cc11215CC8Ab99EFA3613',
  UniversalRouter: '0x597c14B0e494F4502E65Df2984AE018A7EdE8969',
} as const
