import { useEffect } from 'react'
import { useAppDispatch } from 'state/hooks'

export default function useAccountRiskCheck(account: string | null | undefined) {
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (account) {
      try {
        // Noop
      } finally {
      }
    }
  }, [account, dispatch])
}
