import { MANTLE_CHAIN_ID, MANTLE_CONTRACTS } from './mantle'
import { MANTLE_TESTNET_CHAIN_ID, MANTLE_TESTNET_CONTRACTS } from './mantleTestnet'
import { ChainId } from '@uniswap/sdk-core'

export function getMantleContracts(chainId: ChainId = MANTLE_CHAIN_ID) {
  return MANTLE_CHAIN_CONTRACTS[chainId] || MANTLE_CONTRACTS
}
const MANTLE_CHAIN_CONTRACTS: any = {
  [MANTLE_CHAIN_ID]: MANTLE_CONTRACTS,
  [MANTLE_TESTNET_CHAIN_ID]: MANTLE_TESTNET_CONTRACTS,
}
