import { Trans } from '@lingui/macro'
import { FeeAmount } from '@uniswap/v3-sdk'
import classnames from 'classnames'
import { useFeeTierDistribution } from 'hooks/useFeeTierDistribution'
import { PoolState } from 'hooks/usePools'
import React from 'react'
import styled from 'styled-components/macro'
import { ThemedText } from 'theme'

import { FeeTierPercentageBadge } from './FeeTierPercentageBadge'
import { FEE_AMOUNT_DETAIL } from './shared'

const ResponsiveText = styled(ThemedText.DeprecatedLabel)`
  line-height: 16px;
  font-size: 14px;

  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToSmall`
    font-size: 12px;
    line-height: 12px;
  `};
`

interface FeeOptionProps {
  feeAmount: FeeAmount
  active: boolean
  distributions: ReturnType<typeof useFeeTierDistribution>['distributions']
  poolState: PoolState
  onClick: () => void
}

export function FeeOption({ feeAmount, active, poolState, distributions, onClick }: FeeOptionProps) {
  return (
    <button
      className={classnames('border flex flex-col gap-1 bg-section rounded-xl p-3 text-left relative', {
        'border-primary': active,
        'border-section': !active,
      })}
      onClick={onClick}
    >
      <div className="font-bold">
        <Trans>{FEE_AMOUNT_DETAIL[feeAmount].label}%</Trans>
      </div>
      <div className="font-bold text-sm opacity-50">{FEE_AMOUNT_DETAIL[feeAmount].description}</div>

      {distributions && (
        <div className="md:absolute md:right-[0.75rem] md:top-[0.75rem]">
          <FeeTierPercentageBadge distributions={distributions} feeAmount={feeAmount} poolState={poolState} />
        </div>
      )}
    </button>
  )
}
