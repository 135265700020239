import { Trans } from '@lingui/macro'
import { Percent } from '@uniswap/sdk-core'

import SettingsTab from '../Settings'

export default function SwapHeader({ autoSlippage, chainId }: { autoSlippage: Percent; chainId?: number }) {
  return (
    <div className="flex justify-between items-center mb-6">
      <div className="font-bold">
        <Trans>Swap</Trans>
      </div>
      <div>
        <SettingsTab autoSlippage={autoSlippage} chainId={chainId} />
      </div>
    </div>
  )
}
