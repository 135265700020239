import { UNIVERSAL_ROUTER_ADDRESS } from '@uniswap/universal-router-sdk'

import { MANTLE_TESTNET_CHAIN_ID, MANTLE_TESTNET_CONTRACTS } from '../../constants/newChains/mantleTestnet'
import { MANTLE_CHAIN_ID, MANTLE_CONTRACTS } from '../../constants/newChains/mantle'


export function ROUTER_ADDRESS(chainId: number) {
  if (chainId === MANTLE_CHAIN_ID) {
    return MANTLE_CONTRACTS.UniversalRouter
  }

  if (chainId === MANTLE_TESTNET_CHAIN_ID) {
    // return '0x6c633439AB5102F9cc906427Ffaa9b7d94D78D85'
    return MANTLE_TESTNET_CONTRACTS.UniversalRouter
  }

  return UNIVERSAL_ROUTER_ADDRESS(chainId)
}
