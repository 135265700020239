import { Trans } from '@lingui/macro'
import { Button } from 'components/daisy/Button'

interface PresetsButtonsProps {
  onSetFullRange: () => void
}

export default function PresetsButtons({ onSetFullRange }: PresetsButtonsProps) {
  return (
    <Button size={['btn-sm']} onClick={onSetFullRange}>
      <Trans>Full Range</Trans>
    </Button>
  )
}
