import { ChainId, SUPPORTED_CHAINS, SupportedChainsType } from '@uniswap/sdk-core'

import { MANTLE_CHAIN_ID } from './newChains/mantle'
import { MANTLE_TESTNET_CHAIN_ID } from './newChains/mantleTestnet'

export const UniWalletSupportedChains = [] as number[]

export const CHAIN_IDS_TO_NAMES = {
  // [ChainId.MAINNET]: 'mainnet',
  // [ChainId.GOERLI]: 'goerli',
  // [ChainId.SEPOLIA]: 'sepolia',
  // [ChainId.POLYGON]: 'polygon',
  // [ChainId.POLYGON_MUMBAI]: 'polygon_mumbai',
  // [ChainId.CELO]: 'celo',
  // [ChainId.CELO_ALFAJORES]: 'celo_alfajores',
  // [ChainId.ARBITRUM_ONE]: 'arbitrum',
  // [ChainId.ARBITRUM_GOERLI]: 'arbitrum_goerli',
  // [ChainId.OPTIMISM]: 'optimism',
  // [ChainId.OPTIMISM_GOERLI]: 'optimism_goerli',
  // [ChainId.BNB]: 'bnb',
  // [ChainId.AVALANCHE]: 'avalanche',
  [MANTLE_CHAIN_ID]: 'mantle',
  [MANTLE_TESTNET_CHAIN_ID]: 'mantle_testnet',
} as Record<string, string>;

export function isSupportedChain(chainId: number | null | undefined | ChainId): chainId is SupportedChainsType {
  return (
    !!chainId &&
    (SUPPORTED_CHAINS.indexOf(chainId) !== -1 || chainId === MANTLE_CHAIN_ID || chainId === MANTLE_TESTNET_CHAIN_ID)
  )
}

export function asSupportedChain(chainId: number | null | undefined | ChainId): SupportedChainsType | undefined {
  return isSupportedChain(chainId) ? chainId : undefined
}

export const SUPPORTED_GAS_ESTIMATE_CHAIN_IDS = [
  // ChainId.MAINNET,
  // ChainId.POLYGON,
  // ChainId.CELO,
  // ChainId.OPTIMISM,
  // ChainId.ARBITRUM_ONE,
  // ChainId.BNB,
  // ChainId.AVALANCHE,
  // MANTLE_CHAIN_ID, // mantle
  // MANTLE_TESTNET_CHAIN_ID, // mantle_testnet
] as number[]

/**
 * Unsupported networks for V2 pool behavior.
 */
export const UNSUPPORTED_V2POOL_CHAIN_IDS = [
  // ChainId.POLYGON,
  // ChainId.OPTIMISM,
  // ChainId.ARBITRUM_ONE,
  // ChainId.BNB,
  // ChainId.ARBITRUM_GOERLI,
  // ChainId.AVALANCHE,
  MANTLE_CHAIN_ID, // mantle
  MANTLE_TESTNET_CHAIN_ID, // mantle_testnet
] as number[]

export const TESTNET_CHAIN_IDS = [MANTLE_TESTNET_CHAIN_ID] as number[]

/**
 * All the chain IDs that are running the Ethereum protocol.
 */
export const L1_CHAIN_IDS = [] as number[]

export type SupportedL1ChainId = (typeof L1_CHAIN_IDS)[number]

/**
 * Controls some L2 specific behavior, e.g. slippage tolerance, special UI behavior.
 * The expectation is that all of these networks have immediate transaction confirmation.
 */
export const L2_CHAIN_IDS = [MANTLE_CHAIN_ID, MANTLE_TESTNET_CHAIN_ID] as number[] // Should mantle be treat as L1 in our swap?

export type SupportedL2ChainId = (typeof L2_CHAIN_IDS)[number]

export function isPolygonChain(chainId: number): chainId is ChainId.POLYGON | ChainId.POLYGON_MUMBAI {
  return chainId === ChainId.POLYGON || chainId === ChainId.POLYGON_MUMBAI
}
