import { Trans } from '@lingui/macro'
import { formatPriceImpact } from '@uniswap/conedison/format'
import { Percent } from '@uniswap/sdk-core'
import { ColumnCenter } from 'components/Column'
import { Button } from 'components/daisy/Button'
import Row from 'components/Row'
import { AlertTriangle } from 'react-feather'
import styled from 'styled-components/macro'
import { CloseIcon, ThemedText } from 'theme'

import Modal from '../Modal'

const Wrapper = styled(ColumnCenter)`
  padding: 16px 24px;
`

const IconContainer = styled.div`
  padding: 32px 0px;
`

interface PriceImpactModalProps {
  priceImpact: Percent
  onDismiss: () => void
  onContinue: () => void
}

export default function PriceImpactModal({ priceImpact, onDismiss, onContinue }: PriceImpactModalProps) {
  return (
    <Modal isOpen onDismiss={onDismiss}>
      <Wrapper gap="md">
        <Row padding="8px 0px 4px">
          <CloseIcon size={24} onClick={onDismiss} />
        </Row>
        <IconContainer>
          <AlertTriangle className="text-error" size={48} />
        </IconContainer>
        <ColumnCenter gap="sm">
          <ThemedText.HeadlineSmall fontWeight={500}>
            <Trans>Warning</Trans>
          </ThemedText.HeadlineSmall>
          <ThemedText.BodyPrimary lineHeight="24px" textAlign="center">
            <Trans>
              This transaction will result in a <span className="text-error">{formatPriceImpact(priceImpact)}</span>{' '}
              price impact on the market price of this pool. Do you wish to continue?
            </Trans>
          </ThemedText.BodyPrimary>
        </ColumnCenter>
        <div className="flex gap-4 w-full mt-4">
          <Button className="w-1/2" color="error" onClick={onContinue}>
            <Trans>Continue</Trans>
          </Button>
          <Button className="w-1/2" color="neutral" onClick={onDismiss}>
            <Trans>Cancel</Trans>
          </Button>
        </div>
      </Wrapper>
    </Modal>
  )
}
