import { CHAIN_TO_ADDRESSES_MAP, ChainId, Token } from '@uniswap/sdk-core'
import { FACTORY_ADDRESS } from '@uniswap/v3-sdk'

import { MANTLE_CHAIN_ID, MANTLE_TESTNET_CHAIN_ID, NETWORKS_WITH_SAME_UNISWAP_ADDRESSES } from './chains'

const MANTLE_TESTNET_ADDRESSES = {
  v3CoreFactoryAddress: '0x2446E43392152E134ac4D451740fd6C4BCb86612',
  multicallAddress: '0x5C3B7A8b8f7A6Eff5a469A066c398AfC1825eB69',
  quoterAddress: '',
  quoterV2Address: '0x9f7D2198b64816A19F14d8d18b9265399b22283D',
  v3MigratorAddress: '',
  nonfungiblePositionManagerAddress: '0xEA0a988E5AA3C403b2134eD1c3DB9970D01D7464',
  tickLensAddress: '0x61267614E5A2af3f85a1398771c98C6d2e696A32',
  swapRouter02Address: '0x960dec4df02b10a2EEDce1326C50565166715076',
}

const MANTLE_ADDRESSES = {
  v3CoreFactoryAddress: '0xEECa0a86431A7B42ca2Ee5F479832c3D4a4c2644',
  multicallAddress: '0x943Fa56F1399D91260B03f1e056080BBD22654cD',
  quoterAddress: '',
  quoterV2Address: '0x8d8e0C6d1332E5cA51cE4b4370A54c680b014118',
  v3MigratorAddress: '',
  nonfungiblePositionManagerAddress: '0x9aC371240b3dD63Fe5538cAC3f12cea657A3E9A1',
  tickLensAddress: '0xD43b91808a0B888a750C725FBA31404e3b6BD511',
  swapRouter02Address: '0x13a48A555589Ad8223310FA778D5046904970b70',
}

export const BNB_TICK_LENS_ADDRESS = CHAIN_TO_ADDRESSES_MAP[ChainId.BNB].tickLensAddress
export const BNB_NONFUNGIBLE_POSITION_MANAGER_ADDRESS =
  CHAIN_TO_ADDRESSES_MAP[ChainId.BNB].nonfungiblePositionManagerAddress
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const BNB_SWAP_ROUTER_02_ADDRESS = CHAIN_TO_ADDRESSES_MAP[ChainId.BNB].swapRouter02Address!
export const BNB_V3_MIGRATOR_ADDRESS = CHAIN_TO_ADDRESSES_MAP[ChainId.BNB].v3MigratorAddress

export const TICK_LENS_ADDRESSES: AddressMap = {
  [MANTLE_TESTNET_CHAIN_ID]: MANTLE_TESTNET_ADDRESSES.tickLensAddress,
  [MANTLE_CHAIN_ID]: MANTLE_ADDRESSES.tickLensAddress,
}

export const V3_CORE_FACTORY_ADDRESSES: AddressMap = {
  ...constructSameAddressMap(FACTORY_ADDRESS),
  [ChainId.CELO]: CHAIN_TO_ADDRESSES_MAP[ChainId.CELO].v3CoreFactoryAddress,
  [ChainId.CELO_ALFAJORES]: CHAIN_TO_ADDRESSES_MAP[ChainId.CELO_ALFAJORES].v3CoreFactoryAddress,
  [ChainId.OPTIMISM_GOERLI]: CHAIN_TO_ADDRESSES_MAP[ChainId.OPTIMISM_GOERLI].v3CoreFactoryAddress,
  [ChainId.SEPOLIA]: CHAIN_TO_ADDRESSES_MAP[ChainId.SEPOLIA].v3CoreFactoryAddress,
  [ChainId.ARBITRUM_GOERLI]: CHAIN_TO_ADDRESSES_MAP[ChainId.ARBITRUM_GOERLI].v3CoreFactoryAddress,
  [ChainId.BNB]: CHAIN_TO_ADDRESSES_MAP[ChainId.BNB].v3CoreFactoryAddress,
  [ChainId.AVALANCHE]: CHAIN_TO_ADDRESSES_MAP[ChainId.AVALANCHE].v3CoreFactoryAddress,
  // TODO: Gnosis + Moonbeam contracts to be deployed
  [MANTLE_TESTNET_CHAIN_ID]: MANTLE_TESTNET_ADDRESSES.v3CoreFactoryAddress,
  [MANTLE_CHAIN_ID]: MANTLE_ADDRESSES.v3CoreFactoryAddress,
}

export const QUOTER_V2_ADDRESSES: AddressMap = {
  ...constructSameAddressMap('0x61fFE014bA17989E743c5F6cB21bF9697530B21e'),
  [ChainId.CELO]: CHAIN_TO_ADDRESSES_MAP[ChainId.CELO].quoterAddress,
  [ChainId.CELO_ALFAJORES]: CHAIN_TO_ADDRESSES_MAP[ChainId.CELO_ALFAJORES].quoterAddress,
  [ChainId.OPTIMISM_GOERLI]: CHAIN_TO_ADDRESSES_MAP[ChainId.OPTIMISM_GOERLI].quoterAddress,
  [ChainId.SEPOLIA]: CHAIN_TO_ADDRESSES_MAP[ChainId.SEPOLIA].quoterAddress,
  [ChainId.ARBITRUM_GOERLI]: CHAIN_TO_ADDRESSES_MAP[ChainId.ARBITRUM_GOERLI].quoterAddress,
  [ChainId.BNB]: CHAIN_TO_ADDRESSES_MAP[ChainId.BNB].quoterAddress,
  [ChainId.AVALANCHE]: CHAIN_TO_ADDRESSES_MAP[ChainId.AVALANCHE].quoterAddress,
  // TODO: Gnosis + Moonbeam contracts to be deployed
  [MANTLE_TESTNET_CHAIN_ID]: MANTLE_TESTNET_ADDRESSES.quoterV2Address,
  [MANTLE_CHAIN_ID]: MANTLE_ADDRESSES.quoterV2Address,
}

export const MIXED_ROUTE_QUOTER_V1_ADDRESSES: AddressMap = {
  [ChainId.MAINNET]: CHAIN_TO_ADDRESSES_MAP[ChainId.MAINNET].v1MixedRouteQuoterAddress,
  [ChainId.GOERLI]: CHAIN_TO_ADDRESSES_MAP[ChainId.GOERLI].v1MixedRouteQuoterAddress,
}

export const UNISWAP_MULTICALL_ADDRESSES: AddressMap = {
  ...constructSameAddressMap('0x1F98415757620B543A52E61c46B32eB19261F984'),
  [ChainId.CELO]: CHAIN_TO_ADDRESSES_MAP[ChainId.CELO].multicallAddress,
  [ChainId.CELO_ALFAJORES]: CHAIN_TO_ADDRESSES_MAP[ChainId.CELO_ALFAJORES].multicallAddress,
  [ChainId.OPTIMISM_GOERLI]: CHAIN_TO_ADDRESSES_MAP[ChainId.OPTIMISM_GOERLI].multicallAddress,
  [ChainId.SEPOLIA]: CHAIN_TO_ADDRESSES_MAP[ChainId.SEPOLIA].multicallAddress,
  [ChainId.ARBITRUM_GOERLI]: CHAIN_TO_ADDRESSES_MAP[ChainId.ARBITRUM_GOERLI].multicallAddress,
  [ChainId.BNB]: CHAIN_TO_ADDRESSES_MAP[ChainId.BNB].multicallAddress,
  [ChainId.AVALANCHE]: CHAIN_TO_ADDRESSES_MAP[ChainId.AVALANCHE].multicallAddress,
  // TODO: Gnosis + Moonbeam contracts to be deployed
  [MANTLE_TESTNET_CHAIN_ID]: MANTLE_TESTNET_ADDRESSES.multicallAddress,
  [MANTLE_CHAIN_ID]: MANTLE_ADDRESSES.multicallAddress,
}

export const NONFUNGIBLE_POSITION_MANAGER_ADDRESSES: AddressMap = {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  ...constructSameAddressMap(CHAIN_TO_ADDRESSES_MAP[ChainId.MAINNET].nonfungiblePositionManagerAddress!),
  [ChainId.CELO]: CHAIN_TO_ADDRESSES_MAP[ChainId.CELO].nonfungiblePositionManagerAddress,
  [ChainId.CELO_ALFAJORES]: CHAIN_TO_ADDRESSES_MAP[ChainId.CELO_ALFAJORES].nonfungiblePositionManagerAddress,
  [ChainId.OPTIMISM_GOERLI]: CHAIN_TO_ADDRESSES_MAP[ChainId.OPTIMISM_GOERLI].nonfungiblePositionManagerAddress,
  [ChainId.SEPOLIA]: CHAIN_TO_ADDRESSES_MAP[ChainId.SEPOLIA].nonfungiblePositionManagerAddress,
  [ChainId.ARBITRUM_GOERLI]: CHAIN_TO_ADDRESSES_MAP[ChainId.ARBITRUM_GOERLI].nonfungiblePositionManagerAddress,
  [ChainId.BNB]: CHAIN_TO_ADDRESSES_MAP[ChainId.BNB].nonfungiblePositionManagerAddress,
  [ChainId.AVALANCHE]: CHAIN_TO_ADDRESSES_MAP[ChainId.AVALANCHE].nonfungiblePositionManagerAddress,
  // TODO: Gnosis + Moonbeam contracts to be deployed
  [MANTLE_TESTNET_CHAIN_ID]: MANTLE_TESTNET_ADDRESSES.nonfungiblePositionManagerAddress,
  [MANTLE_CHAIN_ID]: MANTLE_ADDRESSES.nonfungiblePositionManagerAddress,
}

export const SWAP_ROUTER_02_ADDRESSES = (chainId: number): string => {
  if (chainId == ChainId.BNB) {
    return BNB_SWAP_ROUTER_02_ADDRESS
  }
  if (chainId === MANTLE_TESTNET_CHAIN_ID) {
    return MANTLE_TESTNET_ADDRESSES.swapRouter02Address
  }
  if (chainId === MANTLE_CHAIN_ID) {
    return MANTLE_ADDRESSES.swapRouter02Address
  }
  return '0x68b3465833fb72A70ecDF485E0e4C7bD8665Fc45'
}

export const OVM_GASPRICE_ADDRESS = '0x420000000000000000000000000000000000000F'
export const ARB_GASINFO_ADDRESS = '0x000000000000000000000000000000000000006C'
export const TICK_LENS_ADDRESS = CHAIN_TO_ADDRESSES_MAP[ChainId.ARBITRUM_ONE].tickLensAddress
export const NONFUNGIBLE_POSITION_MANAGER_ADDRESS =
  CHAIN_TO_ADDRESSES_MAP[ChainId.MAINNET].nonfungiblePositionManagerAddress
export const V3_MIGRATOR_ADDRESS = CHAIN_TO_ADDRESSES_MAP[ChainId.MAINNET].v3MigratorAddress
export const MULTICALL2_ADDRESS = '0x5BA1e12693Dc8F9c48aAD8770482f4739bEeD696'

export type AddressMap = { [chainId: number]: string | undefined }

export function constructSameAddressMap<T extends string>(
  address: T,
  additionalNetworks: ChainId[] = []
): { [chainId: number]: T } {
  return NETWORKS_WITH_SAME_UNISWAP_ADDRESSES.concat(additionalNetworks).reduce<{
    [chainId: number]: T
  }>((memo, chainId) => {
    memo[chainId] = address
    return memo
  }, {})
}

export const WETH9: {
  [chainId in Exclude<
    ChainId,
    | ChainId.POLYGON
    | ChainId.POLYGON_MUMBAI
    | ChainId.CELO
    | ChainId.CELO_ALFAJORES
    | ChainId.GNOSIS
    | ChainId.MOONBEAM
    | ChainId.BNB
    | ChainId.AVALANCHE
  >]: Token
} = {
  [ChainId.MAINNET]: new Token(
    ChainId.MAINNET,
    '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
    18,
    'WETH',
    'Wrapped Ether'
  ),
  [ChainId.GOERLI]: new Token(
    ChainId.GOERLI,
    '0xB4FBF271143F4FBf7B91A5ded31805e42b2208d6',
    18,
    'WETH',
    'Wrapped Ether'
  ),
  [ChainId.SEPOLIA]: new Token(
    ChainId.SEPOLIA,
    '0xfFf9976782d46CC05630D1f6eBAb18b2324d6B14',
    18,
    'WETH',
    'Wrapped Ether'
  ),
  [ChainId.OPTIMISM]: new Token(
    ChainId.OPTIMISM,
    '0x4200000000000000000000000000000000000006',
    18,
    'WETH',
    'Wrapped Ether'
  ),
  [ChainId.OPTIMISM_GOERLI]: new Token(
    ChainId.OPTIMISM_GOERLI,
    '0x4200000000000000000000000000000000000006',
    18,
    'WETH',
    'Wrapped Ether'
  ),
  [ChainId.ARBITRUM_ONE]: new Token(
    ChainId.ARBITRUM_ONE,
    '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1',
    18,
    'WETH',
    'Wrapped Ether'
  ),
  [ChainId.ARBITRUM_GOERLI]: new Token(
    ChainId.ARBITRUM_GOERLI,
    '0xe39Ab88f8A4777030A534146A9Ca3B52bd5D43A3',
    18,
    'WETH',
    'Wrapped Ether'
  ),
}
