import classNames from 'classnames'

import { Color, ResponsivePrefix } from './utils'

type ButtonSize = 'btn-xs' | 'btn-sm' | 'btn-md' | 'btn-lg'

type ButtonBlock = 'btn-block'

export interface ButtonProps {
  color?: Color
  type?: 'submit' | 'button' | 'reset'
  visual?: 'solid' | 'ghost' | 'outline' | 'link'
  disabled?: boolean
  active?: boolean
  shape?: 'long' | 'circle' | 'square'
  size?: (ButtonSize | `${ResponsivePrefix}:${ButtonSize}`)[]
  block?: ButtonBlock | `${ResponsivePrefix}:${ButtonBlock}`
  loading?: boolean
  tag?: 'button' | 'div'
  // External link
  href?: string
  // Internal link
  link?: string
  target?: string
  children: React.ReactNode
  className?: string
  id?: string
  onClick?: (evt: any) => void // Lazy typings, event can be a keyboard/mouse event
}

export function Button({
  className,
  color,
  visual,
  size = [],
  type = 'button',
  disabled,
  active,
  shape,
  loading,
  block,
  children,
  href,
  target,
  link,
  tag,
  id,
  onClick,
}: ButtonProps) {
  const combined = classNames(
    'btn',
    size,
    block,
    {
      'btn-primary': color === 'primary',
      'btn-secondary': color === 'secondary',
      'btn-neutral': color === 'neutral',
      'btn-accent': color === 'accent',
      'btn-success': color === 'success',
      'btn-warning': color === 'warning',
      'btn-error': color === 'error',
      'btn-ghost': visual === 'ghost',
      'btn-outline': visual === 'outline',
      'btn-link': visual === 'link',
      disabled,
      'border border-primary': color === 'primary' && disabled,
      'btn-active': active,
      'btn-circle': shape === 'circle',
      'btn-square': shape === 'square',
      loading,
    },
    className
  )

  if (href) {
    return (
      <a className={combined} href={href} target={target} onClick={onClick}>
        {children}
      </a>
    )
  }

  if (link) {
    return (
      <a id={id} href={link} className={combined} onClick={onClick}>
        {children}
      </a>
    )
  }

  if (tag === 'div') {
    return (
      <div id={id} className={combined} onClick={onClick} onKeyUp={onClick} role="button" tabIndex={0}>
        {children}
      </div>
    )
  }

  return (
    <button
      id={id}
      className={combined}
      disabled={disabled || loading}
      onClick={onClick}
      // eslint-disable-next-line react/button-has-type
      type={type}
    >
      {children}
    </button>
  )
}
