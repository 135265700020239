export const UNSUPPORTED_TOKENS = {
  name: 'Unsupported Token List',
  timestamp: '2021-01-05T20:47:02.923Z',
  version: {
    major: 1,
    minor: 0,
    patch: 0,
  },
  tags: {},
  logoURI: 'ipfs://QmNa8mQkrNKp1WEEeGjFezDmDeodkWRevGFN8JCV7b4Xir',
  keywords: ['uniswap', 'unsupported', 'broken'],
  tokens: [
    {
      name: 'USD Token',
      address: '0xd233d1f6fd11640081abb8db125f722b5dc729dc',
      symbol: 'USD',
      decimals: 9,
      chainId: 1,
      logoURI: '',
    },
    {
      name: 'UNI HODL',
      address: '0x4bf5dc91E2555449293D7824028Eb8Fe5879B689',
      symbol: 'UniH',
      decimals: 18,
      chainId: 1,
      logoURI: '',
    },
    {
      name: 'Gold Tether',
      address: '0x4922a015c4407F87432B179bb209e125432E4a2A',
      symbol: 'XAUt',
      decimals: 6,
      chainId: 1,
      logoURI:
        'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x4922a015c4407F87432B179bb209e125432E4a2A/logo.png',
    },
    {
      name: 'Grump Cat',
      address: '0x93B2FfF814FCaEFFB01406e80B4Ecd89Ca6A021b',
      symbol: 'GRUMPY',
      decimals: 9,
      chainId: 1,
      logoURI:
        'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x4922a015c4407F87432B179bb209e125432E4a2A/logo.png',
    },
    {
      name: 'apeUSD-UMA Synthetic USD (Dec 2021)',
      address: '0xfA5e27893aee4805283D86e4283Da64F8c72dd56',
      symbol: 'apeUSD-UMA-DEC21',
      decimals: 18,
      chainId: 1,
      logoURI: '',
    },
    {
      chainId: 1,
      address: '0xc6b11850241c5127eab73af4b6c68bc267cbbff4',
      name: 'oWETHp Put 360 DEC2520',
      symbol: 'oWETH-360P-12/25/20',
      decimals: 7,
    },
    {
      chainId: 1,
      name: 'oYFIp Put 25000 OCT0220',
      address: '0x452b421be5b30f0c6ad8c3f03c06bdaab4f5c56c',
      symbol: 'oYFI-25000P-10/02/20',
      decimals: 7,
    },
    {
      chainId: 1,
      name: 'oWETHp Put 360 OCT3020',
      address: '0x0578779e746d7186253a36cf651ea786acfcf087',
      symbol: 'oWETH-360P-10/30/20',
      decimals: 7,
    },
    {
      chainId: 1,
      name: 'ETHc Call 500 OCT3020',
      address: '0xf9aba2e43fb19184408ea3b572a0fd672946f87b',
      symbol: 'oETH-500C-10/30/20',
      decimals: 6,
    },
    {
      chainId: 1,
      name: 'oBALp Put 22 OCT0220',
      address: '0xdb0991dfc7e828b5a2837dc82d68e16490562c8d',
      symbol: 'oBAL-22P-10/02/20',
      decimals: 7,
    },
    {
      chainId: 1,
      name: 'oCOMPp Put 150 SEP2520',
      address: '0xe951ebe6b4420ab3f4844cf36dedd263d095b416',
      symbol: 'oCOMP-150P-09/25/20',
      decimals: 7,
    },
    {
      chainId: 1,
      name: 'oCRVp Put 3 SEP252020',
      address: '0x9215bd49b59748419eac6bad9dbe247df06ebdb9',
      symbol: 'oCRV-3P-09/25/20',
      decimals: 7,
    },
    {
      chainId: 1,
      name: 'oETHp Put 180 SEP2520',
      address: '0xE3A2c34Fa2F59ffa95C4ACd1E5663633d45Bc3AD',
      symbol: 'oETH-180P-09/25/20',
      decimals: 7,
    },
    {
      chainId: 1,
      name: 'oETHc Call 400 SEP2520',
      address: '0x05977EBC26825C0CD6097E0Ad7204721516711Eb',
      symbol: 'oETH-400C-09/25/20',
      decimals: 7,
    },
    {
      chainId: 1,
      name: 'oWETHp Put 380 SEP1820',
      address: '0x31f88266301b08631f9f0e33fd5c43c2a5d1e5b2',
      symbol: 'oWETH-380P-09/18/20',
      decimals: 7,
    },
    {
      chainId: 1,
      name: 'oYFIp Put 8500 SEP1820',
      address: '0xd1cec2f67fdc4c60e0963515dfc3343f31e32e47',
      symbol: 'oYFI-8500P-09/18/20',
      decimals: 7,
    },
    {
      chainId: 1,
      name: 'oWETHp Put 370 SEP1120',
      address: '0x15844029b2c2bf24506e9937739a9a912f1e4354',
      symbol: 'oWETH-370P-09/11/20',
      decimals: 7,
    },
    {
      chainId: 1,
      name: 'oWETHp Put 400 SEP0420',
      address: '0x5562c33c383f6386be4f6dcdbd35a3a99bbcfde6',
      symbol: 'oWETH-400P-09/04/20',
      decimals: 7,
    },
    {
      chainId: 1,
      name: 'oETHp Put 200 AUG2820',
      address: '0x3CBFC1397deF0602c2d211c70A1c0c38CEDB5448',
      symbol: 'oWETH-400P-09/04/20',
      decimals: 7,
    },
    {
      chainId: 1,
      name: 'Opyn cDai Insurance',
      symbol: 'ocDai',
      address: '0x98cc3bd6af1880fcfda17ac477b2f612980e5e33',
      decimals: 8,
    },
    {
      chainId: 1,
      name: 'Opyn cUSDC Insurance',
      symbol: 'ocUSDC',
      address: '0x8ED9f862363fFdFD3a07546e618214b6D59F03d4',
      decimals: 8,
    },
    {
      chainId: 1,
      address: '0x176C674Ee533C6139B0dc8b458D72A93dCB3e705',
      symbol: 'iAAVE',
      name: 'Synth Inverse Aave',
      decimals: 18,
      logoURI: 'https://raw.githubusercontent.com/Synthetixio/synthetix-assets/v2.0.10/synths/iAAVE.svg',
      tags: ['inverse', 'synth'],
    },
    {
      chainId: 1,
      address: '0x8A8079c7149B8A1611e5C5d978DCA3bE16545F83',
      symbol: 'iADA',
      name: 'Synth Inverse Cardano',
      decimals: 18,
      logoURI: 'https://raw.githubusercontent.com/Synthetixio/synthetix-assets/v2.0.10/synths/iADA.svg',
      tags: ['inverse', 'synth'],
    },
    {
      chainId: 1,
      address: '0xAFD870F32CE54EfdBF677466B612bf8ad164454B',
      symbol: 'iBNB',
      name: 'Synth Inverse Binance Coin',
      decimals: 18,
      logoURI: 'https://raw.githubusercontent.com/Synthetixio/synthetix-assets/v2.0.10/synths/iBNB.svg',
      tags: ['inverse', 'synth'],
    },
    {
      chainId: 1,
      address: '0xD6014EA05BDe904448B743833dDF07c3C7837481',
      symbol: 'iBTC',
      name: 'Synth Inverse Bitcoin',
      decimals: 18,
      logoURI: 'https://raw.githubusercontent.com/Synthetixio/synthetix-assets/v2.0.10/synths/iBTC.svg',
      tags: ['inverse', 'synth'],
    },
    {
      chainId: 1,
      address: '0x336213e1DDFC69f4701Fc3F86F4ef4A160c1159d',
      symbol: 'iCEX',
      name: 'Synth Inverse Centralised Exchange Index',
      decimals: 18,
      logoURI: 'https://raw.githubusercontent.com/Synthetixio/synthetix-assets/v2.0.10/synths/iCEX.svg',
      tags: ['index', 'inverse', 'synth'],
    },
    {
      chainId: 1,
      address: '0x6345728B1ccE16E6f8C509950b5c84FFF88530d9',
      symbol: 'iCOMP',
      name: 'Synth Inverse Compound',
      decimals: 18,
      logoURI: 'https://raw.githubusercontent.com/Synthetixio/synthetix-assets/v2.0.10/synths/iCOMP.svg',
      tags: ['inverse', 'synth'],
    },
    {
      chainId: 1,
      address: '0xCB98f42221b2C251A4E74A1609722eE09f0cc08E',
      symbol: 'iDASH',
      name: 'Synth Inverse Dash',
      decimals: 18,
      logoURI: 'https://raw.githubusercontent.com/Synthetixio/synthetix-assets/v2.0.10/synths/iDASH.svg',
      tags: ['inverse', 'synth'],
    },
    {
      chainId: 1,
      address: '0x14d10003807AC60d07BB0ba82cAeaC8d2087c157',
      symbol: 'iDEFI',
      name: 'Synth Inverse DeFi Index',
      decimals: 18,
      logoURI: 'https://raw.githubusercontent.com/Synthetixio/synthetix-assets/v2.0.10/synths/iDEFI.svg',
      tags: ['index', 'inverse', 'synth'],
    },
    {
      chainId: 1,
      address: '0x46a97629C9C1F58De6EC18C7F536e7E6d6A6ecDe',
      symbol: 'iDOT',
      name: 'Synth Inverse Polkadot',
      decimals: 18,
      logoURI: 'https://raw.githubusercontent.com/Synthetixio/synthetix-assets/v2.0.10/synths/iDOT.svg',
      tags: ['inverse', 'synth'],
    },
    {
      chainId: 1,
      address: '0xF4EebDD0704021eF2a6Bbe993fdf93030Cd784b4',
      symbol: 'iEOS',
      name: 'Synth Inverse EOS',
      decimals: 18,
      logoURI: 'https://raw.githubusercontent.com/Synthetixio/synthetix-assets/v2.0.10/synths/iEOS.svg',
      tags: ['inverse', 'synth'],
    },
    {
      chainId: 1,
      address: '0xd50c1746D835d2770dDA3703B69187bFfeB14126',
      symbol: 'iETC',
      name: 'Synth Inverse Ethereum Classic',
      decimals: 18,
      logoURI: 'https://raw.githubusercontent.com/Synthetixio/synthetix-assets/v2.0.10/synths/iETC.svg',
      tags: ['inverse', 'synth'],
    },
    {
      chainId: 1,
      address: '0xA9859874e1743A32409f75bB11549892138BBA1E',
      symbol: 'iETH',
      name: 'Synth Inverse Ether',
      decimals: 18,
      logoURI: 'https://raw.githubusercontent.com/Synthetixio/synthetix-assets/v2.0.10/synths/iETH.svg',
      tags: ['inverse', 'synth'],
    },
    {
      chainId: 1,
      address: '0x2d7aC061fc3db53c39fe1607fB8cec1B2C162B01',
      symbol: 'iLINK',
      name: 'Synth Inverse Chainlink',
      decimals: 18,
      logoURI: 'https://raw.githubusercontent.com/Synthetixio/synthetix-assets/v2.0.10/synths/iLINK.svg',
      tags: ['inverse', 'synth'],
    },
    {
      chainId: 1,
      address: '0x79da1431150C9b82D2E5dfc1C68B33216846851e',
      symbol: 'iLTC',
      name: 'Synth Inverse Litecoin',
      decimals: 18,
      logoURI: 'https://raw.githubusercontent.com/Synthetixio/synthetix-assets/v2.0.10/synths/iLTC.svg',
      tags: ['inverse', 'synth'],
    },
    {
      chainId: 1,
      address: '0xA5a5DF41883Cdc00c4cCC6E8097130535399d9a3',
      symbol: 'iOIL',
      name: 'Synth Inverse Perpetual Oil Futures',
      decimals: 18,
      logoURI: 'https://raw.githubusercontent.com/Synthetixio/synthetix-assets/v2.0.10/synths/iOIL.svg',
      tags: ['inverse', 'synth'],
    },
    {
      chainId: 1,
      address: '0x0fEd38108bdb8e62ef7b5680E8E0726E2F29e0De',
      symbol: 'iREN',
      name: 'Synth Inverse Ren',
      decimals: 18,
      logoURI: 'https://raw.githubusercontent.com/Synthetixio/synthetix-assets/v2.0.10/synths/iREN.svg',
      tags: ['inverse', 'synth'],
    },
    {
      chainId: 1,
      address: '0xC5807183a9661A533CB08CbC297594a0B864dc12',
      symbol: 'iTRX',
      name: 'Synth Inverse TRON',
      decimals: 18,
      logoURI: 'https://raw.githubusercontent.com/Synthetixio/synthetix-assets/v2.0.10/synths/iTRX.svg',
      tags: ['inverse', 'synth'],
    },
    {
      chainId: 1,
      address: '0x36A00FF9072570eF4B9292117850B8FE08d96cce',
      symbol: 'iUNI',
      name: 'Synth Inverse Uniswap',
      decimals: 18,
      logoURI: 'https://raw.githubusercontent.com/Synthetixio/synthetix-assets/v2.0.10/synths/iUNI.svg',
      tags: ['inverse', 'synth'],
    },
    {
      chainId: 1,
      address: '0x4AdF728E2Df4945082cDD6053869f51278fae196',
      symbol: 'iXMR',
      name: 'Synth Inverse Monero',
      decimals: 18,
      logoURI: 'https://raw.githubusercontent.com/Synthetixio/synthetix-assets/v2.0.10/synths/iXMR.svg',
      tags: ['inverse', 'synth'],
    },
    {
      chainId: 1,
      address: '0x27269b3e45A4D3E79A3D6BFeE0C8fB13d0D711A6',
      symbol: 'iXRP',
      name: 'Synth Inverse Ripple',
      decimals: 18,
      logoURI: 'https://raw.githubusercontent.com/Synthetixio/synthetix-assets/v2.0.10/synths/iXRP.svg',
      tags: ['inverse', 'synth'],
    },
    {
      chainId: 1,
      address: '0x8deef89058090ac5655A99EEB451a4f9183D1678',
      symbol: 'iXTZ',
      name: 'Synth Inverse Tezos',
      decimals: 18,
      logoURI: 'https://raw.githubusercontent.com/Synthetixio/synthetix-assets/v2.0.10/synths/iXTZ.svg',
      tags: ['inverse', 'synth'],
    },
    {
      chainId: 1,
      address: '0x592244301CeA952d6daB2fdC1fE6bd9E53917306',
      symbol: 'iYFI',
      name: 'Synth Inverse yearn.finance',
      decimals: 18,
      logoURI: 'https://raw.githubusercontent.com/Synthetixio/synthetix-assets/v2.0.10/synths/iYFI.svg',
      tags: ['inverse', 'synth'],
    },
    {
      chainId: 1,
      address: '0xcD39b5434a0A92cf47D1F567a7dF84bE356814F0',
      symbol: 's1INCH',
      name: 'Synth 1inch',
      decimals: 18,
      logoURI: 'https://raw.githubusercontent.com/Synthetixio/synthetix-assets/v2.0.10/synths/s1INCH.svg',
      tags: ['synth'],
    },
    {
      chainId: 1,
      address: '0x7537AAe01f3B218DAE75e10d952473823F961B87',
      symbol: 'sAAPL',
      name: 'Synth Apple',
      decimals: 18,
      logoURI: 'https://raw.githubusercontent.com/Synthetixio/synthetix-assets/v2.0.10/synths/sAAPL.svg',
      tags: ['synth'],
    },
    {
      chainId: 1,
      address: '0xd2dF355C19471c8bd7D8A3aa27Ff4e26A21b4076',
      symbol: 'sAAVE',
      name: 'Synth Aave',
      decimals: 18,
      logoURI: 'https://raw.githubusercontent.com/Synthetixio/synthetix-assets/v2.0.10/synths/sAAVE.svg',
      tags: ['synth'],
    },
    {
      chainId: 1,
      address: '0xe36E2D3c7c34281FA3bC737950a68571736880A1',
      symbol: 'sADA',
      name: 'Synth Cardano',
      decimals: 18,
      logoURI: 'https://raw.githubusercontent.com/Synthetixio/synthetix-assets/v2.0.10/synths/sADA.svg',
      tags: ['synth'],
    },
    {
      chainId: 1,
      address: '0x9CF7E61853ea30A41b02169391b393B901eac457',
      symbol: 'sAMZN',
      name: 'Synth Amazon',
      decimals: 18,
      logoURI: 'https://raw.githubusercontent.com/Synthetixio/synthetix-assets/v2.0.10/synths/sAMZN.svg',
      tags: ['synth'],
    },
    {
      chainId: 1,
      address: '0xF48e200EAF9906362BB1442fca31e0835773b8B4',
      symbol: 'sAUD',
      name: 'Synth Australian Dollars',
      decimals: 18,
      logoURI: 'https://raw.githubusercontent.com/Synthetixio/synthetix-assets/v2.0.10/synths/sAUD.svg',
      tags: ['synth'],
    },
    {
      chainId: 1,
      address: '0x617aeCB6137B5108D1E7D4918e3725C8cEbdB848',
      symbol: 'sBNB',
      name: 'Synth Binance Coin',
      decimals: 18,
      logoURI: 'https://raw.githubusercontent.com/Synthetixio/synthetix-assets/v2.0.10/synths/sBNB.svg',
      tags: ['synth'],
    },
    {
      chainId: 1,
      address: '0xfE18be6b3Bd88A2D2A7f928d00292E7a9963CfC6',
      symbol: 'sBTC',
      name: 'Synth Bitcoin',
      decimals: 18,
      logoURI: 'https://raw.githubusercontent.com/Synthetixio/synthetix-assets/v2.0.10/synths/sBTC.svg',
      tags: ['synth'],
    },
    {
      chainId: 1,
      address: '0xeABACD844A196D7Faf3CE596edeBF9900341B420',
      symbol: 'sCEX',
      name: 'Synth Centralised Exchange Index',
      decimals: 18,
      logoURI: 'https://raw.githubusercontent.com/Synthetixio/synthetix-assets/v2.0.10/synths/sCEX.svg',
      tags: ['index', 'synth'],
    },
    {
      chainId: 1,
      address: '0x0F83287FF768D1c1e17a42F44d644D7F22e8ee1d',
      symbol: 'sCHF',
      name: 'Synth Swiss Franc',
      decimals: 18,
      logoURI: 'https://raw.githubusercontent.com/Synthetixio/synthetix-assets/v2.0.10/synths/sCHF.svg',
      tags: ['synth'],
    },
    {
      chainId: 1,
      address: '0x9EeF4CA7aB9fa8bc0650127341C2d3F707a40f8A',
      symbol: 'sCOIN',
      name: 'Synth Coinbase',
      decimals: 18,
      logoURI: 'https://raw.githubusercontent.com/Synthetixio/synthetix-assets/v2.0.10/synths/sCOIN.svg',
      tags: ['synth'],
    },
    {
      chainId: 1,
      address: '0xEb029507d3e043DD6C87F2917C4E82B902c35618',
      symbol: 'sCOMP',
      name: 'Synth Compound',
      decimals: 18,
      logoURI: 'https://raw.githubusercontent.com/Synthetixio/synthetix-assets/v2.0.10/synths/sCOMP.svg',
      tags: ['synth'],
    },
    {
      chainId: 1,
      address: '0xD38aEb759891882e78E957c80656572503D8c1B1',
      symbol: 'sCRV',
      name: 'Synth Curve DAO Token',
      decimals: 18,
      logoURI: 'https://raw.githubusercontent.com/Synthetixio/synthetix-assets/v2.0.10/synths/sCRV.svg',
      tags: ['synth'],
    },
    {
      chainId: 1,
      address: '0xfE33ae95A9f0DA8A845aF33516EDc240DCD711d6',
      symbol: 'sDASH',
      name: 'Synth Dash',
      decimals: 18,
      logoURI: 'https://raw.githubusercontent.com/Synthetixio/synthetix-assets/v2.0.10/synths/sDASH.svg',
      tags: ['synth'],
    },
    {
      chainId: 1,
      address: '0xe1aFe1Fd76Fd88f78cBf599ea1846231B8bA3B6B',
      symbol: 'sDEFI',
      name: 'Synth DeFi Index',
      decimals: 18,
      logoURI: 'https://raw.githubusercontent.com/Synthetixio/synthetix-assets/v2.0.10/synths/sDEFI.svg',
      tags: ['index', 'synth'],
    },
    {
      chainId: 1,
      address: '0x1715AC0743102BF5Cd58EfBB6Cf2dC2685d967b6',
      symbol: 'sDOT',
      name: 'Synth Polkadot',
      decimals: 18,
      logoURI: 'https://raw.githubusercontent.com/Synthetixio/synthetix-assets/v2.0.10/synths/sDOT.svg',
      tags: ['synth'],
    },
    {
      chainId: 1,
      address: '0x88C8Cf3A212c0369698D13FE98Fcb76620389841',
      symbol: 'sEOS',
      name: 'Synth EOS',
      decimals: 18,
      logoURI: 'https://raw.githubusercontent.com/Synthetixio/synthetix-assets/v2.0.10/synths/sEOS.svg',
      tags: ['synth'],
    },
    {
      chainId: 1,
      address: '0x22602469d704BfFb0936c7A7cfcD18f7aA269375',
      symbol: 'sETC',
      name: 'Synth Ethereum Classic',
      decimals: 18,
      logoURI: 'https://raw.githubusercontent.com/Synthetixio/synthetix-assets/v2.0.10/synths/sETC.svg',
      tags: ['synth'],
    },
    {
      chainId: 1,
      address: '0x5e74C9036fb86BD7eCdcb084a0673EFc32eA31cb',
      symbol: 'sETH',
      name: 'Synth Ether',
      decimals: 18,
      logoURI: 'https://raw.githubusercontent.com/Synthetixio/synthetix-assets/v2.0.10/synths/sETH.svg',
      tags: ['synth'],
    },
    {
      chainId: 1,
      address: '0xD71eCFF9342A5Ced620049e616c5035F1dB98620',
      symbol: 'sEUR',
      name: 'Synth Euros',
      decimals: 18,
      logoURI: 'https://raw.githubusercontent.com/Synthetixio/synthetix-assets/v2.0.10/synths/sEUR.svg',
      tags: ['synth'],
    },
    {
      chainId: 1,
      address: '0xf50B5e535F62a56A9BD2d8e2434204E726c027Fa',
      symbol: 'sFB',
      name: 'Synth Facebook',
      decimals: 18,
      logoURI: 'https://raw.githubusercontent.com/Synthetixio/synthetix-assets/v2.0.10/synths/sFB.svg',
      tags: ['synth'],
    },
    {
      chainId: 1,
      address: '0x23348160D7f5aca21195dF2b70f28Fce2B0be9fC',
      symbol: 'sFTSE',
      name: 'Synth FTSE 100 Index',
      decimals: 18,
      logoURI: 'https://raw.githubusercontent.com/Synthetixio/synthetix-assets/v2.0.10/synths/sFTSE.svg',
      tags: ['synth'],
    },
    {
      chainId: 1,
      address: '0x97fe22E7341a0Cd8Db6F6C021A24Dc8f4DAD855F',
      symbol: 'sGBP',
      name: 'Synth Pound Sterling',
      decimals: 18,
      logoURI: 'https://raw.githubusercontent.com/Synthetixio/synthetix-assets/v2.0.10/synths/sGBP.svg',
      tags: ['synth'],
    },
    {
      chainId: 1,
      address: '0xC63B8ECCE56aB9C46184eC6aB85e4771fEa4c8AD',
      symbol: 'sGOOG',
      name: 'Synth Alphabet',
      decimals: 18,
      logoURI: 'https://raw.githubusercontent.com/Synthetixio/synthetix-assets/v2.0.10/synths/sGOOG.svg',
      tags: ['synth'],
    },
    {
      chainId: 1,
      address: '0xF6b1C627e95BFc3c1b4c9B825a032Ff0fBf3e07d',
      symbol: 'sJPY',
      name: 'Synth Japanese Yen',
      decimals: 18,
      logoURI: 'https://raw.githubusercontent.com/Synthetixio/synthetix-assets/v2.0.10/synths/sJPY.svg',
      tags: ['synth'],
    },
    {
      chainId: 1,
      address: '0x269895a3dF4D73b077Fc823dD6dA1B95f72Aaf9B',
      symbol: 'sKRW',
      name: 'Synth South Korean Won',
      decimals: 18,
      logoURI: 'https://raw.githubusercontent.com/Synthetixio/synthetix-assets/v2.0.10/synths/sKRW.svg',
      tags: ['synth'],
    },
    {
      chainId: 1,
      address: '0xbBC455cb4F1B9e4bFC4B73970d360c8f032EfEE6',
      symbol: 'sLINK',
      name: 'Synth Chainlink',
      decimals: 18,
      logoURI: 'https://raw.githubusercontent.com/Synthetixio/synthetix-assets/v2.0.10/synths/sLINK.svg',
      tags: ['synth'],
    },
    {
      chainId: 1,
      address: '0xC14103C2141E842e228FBaC594579e798616ce7A',
      symbol: 'sLTC',
      name: 'Synth Litecoin',
      decimals: 18,
      logoURI: 'https://raw.githubusercontent.com/Synthetixio/synthetix-assets/v2.0.10/synths/sLTC.svg',
      tags: ['synth'],
    },
    {
      chainId: 1,
      address: '0x745a824D6aBBD236AA794b5530062778A6Ad7523',
      symbol: 'sMSFT',
      name: 'Synth Microsoft',
      decimals: 18,
      logoURI: 'https://raw.githubusercontent.com/Synthetixio/synthetix-assets/v2.0.10/synths/sMSFT.svg',
      tags: ['synth'],
    },
    {
      chainId: 1,
      address: '0x5A7E3c07604EB515C16b36cd51906a65f021F609',
      symbol: 'sNFLX',
      name: 'Synth Netflix',
      decimals: 18,
      logoURI: 'https://raw.githubusercontent.com/Synthetixio/synthetix-assets/v2.0.10/synths/sNFLX.svg',
      tags: ['synth'],
    },
    {
      chainId: 1,
      address: '0x757de3ac6B830a931eF178C6634c5C551773155c',
      symbol: 'sNIKKEI',
      name: 'Synth Nikkei 225 Index',
      decimals: 18,
      logoURI: 'https://raw.githubusercontent.com/Synthetixio/synthetix-assets/v2.0.10/synths/sNIKKEI.svg',
      tags: ['synth'],
    },
    {
      chainId: 1,
      address: '0x6d16cF3EC5F763d4d99cB0B0b110eefD93B11B56',
      symbol: 'sOIL',
      name: 'Synth Perpetual Oil Futures',
      decimals: 18,
      logoURI: 'https://raw.githubusercontent.com/Synthetixio/synthetix-assets/v2.0.10/synths/sOIL.svg',
      tags: ['synth'],
    },
    {
      chainId: 1,
      address: '0xD31533E8d0f3DF62060e94B3F1318137bB6E3525',
      symbol: 'sREN',
      name: 'Synth Ren',
      decimals: 18,
      logoURI: 'https://raw.githubusercontent.com/Synthetixio/synthetix-assets/v2.0.10/synths/sREN.svg',
      tags: ['synth'],
    },
    {
      chainId: 1,
      address: '0x0352557B007A4Aae1511C114409b932F06F9E2f4',
      symbol: 'sRUNE',
      name: 'Synth THORChain',
      decimals: 18,
      logoURI: 'https://raw.githubusercontent.com/Synthetixio/synthetix-assets/v2.0.10/synths/sRUNE.svg',
      tags: ['synth'],
    },
    {
      chainId: 1,
      address: '0xf2E08356588EC5cd9E437552Da87C0076b4970B0',
      symbol: 'sTRX',
      name: 'Synth TRON',
      decimals: 18,
      logoURI: 'https://raw.githubusercontent.com/Synthetixio/synthetix-assets/v2.0.10/synths/sTRX.svg',
      tags: ['synth'],
    },
    {
      chainId: 1,
      address: '0x918dA91Ccbc32B7a6A0cc4eCd5987bbab6E31e6D',
      symbol: 'sTSLA',
      name: 'Synth Tesla',
      decimals: 18,
      logoURI: 'https://raw.githubusercontent.com/Synthetixio/synthetix-assets/v2.0.10/synths/sTSLA.svg',
      tags: ['synth'],
    },
    {
      chainId: 1,
      address: '0x30635297E450b930f8693297eBa160D9e6c8eBcf',
      symbol: 'sUNI',
      name: 'Synth Uniswap',
      decimals: 18,
      logoURI: 'https://raw.githubusercontent.com/Synthetixio/synthetix-assets/v2.0.10/synths/sUNI.svg',
      tags: ['synth'],
    },
    {
      chainId: 1,
      address: '0x6A22e5e94388464181578Aa7A6B869e00fE27846',
      symbol: 'sXAG',
      name: 'Synth Silver Ounce',
      decimals: 18,
      logoURI: 'https://raw.githubusercontent.com/Synthetixio/synthetix-assets/v2.0.10/synths/sXAG.svg',
      tags: ['synth'],
    },
    {
      chainId: 1,
      address: '0x261EfCdD24CeA98652B9700800a13DfBca4103fF',
      symbol: 'sXAU',
      name: 'Synth Gold Ounce',
      decimals: 18,
      logoURI: 'https://raw.githubusercontent.com/Synthetixio/synthetix-assets/v2.0.10/synths/sXAU.svg',
      tags: ['synth'],
    },
    {
      chainId: 1,
      address: '0x5299d6F7472DCc137D7f3C4BcfBBB514BaBF341A',
      symbol: 'sXMR',
      name: 'Synth Monero',
      decimals: 18,
      logoURI: 'https://raw.githubusercontent.com/Synthetixio/synthetix-assets/v2.0.10/synths/sXMR.svg',
      tags: ['synth'],
    },
    {
      chainId: 1,
      address: '0xa2B0fDe6D710e201d0d608e924A484d1A5fEd57c',
      symbol: 'sXRP',
      name: 'Synth Ripple',
      decimals: 18,
      logoURI: 'https://raw.githubusercontent.com/Synthetixio/synthetix-assets/v2.0.10/synths/sXRP.svg',
      tags: ['synth'],
    },
    {
      chainId: 1,
      address: '0x2e59005c5c0f0a4D77CcA82653d48b46322EE5Cd',
      symbol: 'sXTZ',
      name: 'Synth Tezos',
      decimals: 18,
      logoURI: 'https://raw.githubusercontent.com/Synthetixio/synthetix-assets/v2.0.10/synths/sXTZ.svg',
      tags: ['synth'],
    },
    {
      chainId: 1,
      address: '0x992058B7DB08F9734d84485bfbC243C4ee6954A7',
      symbol: 'sYFI',
      name: 'Synth yearn.finance',
      decimals: 18,
      logoURI: 'https://raw.githubusercontent.com/Synthetixio/synthetix-assets/v2.0.10/synths/sYFI.svg',
      tags: ['synth'],
    },
    {
      chainId: 1,
      address: '0x81ab848898b5ffD3354dbbEfb333D5D183eEDcB5',
      name: 'yUSD Synthetic Expiring 1 September 2020',
      symbol: 'yUSDSEP20',
      decimals: 18,
    },
    {
      chainId: 1,
      address: '0xB2FdD60AD80ca7bA89B9BAb3b5336c2601C020b4',
      name: 'yUSD Synthetic Expiring 1 October 2020',
      symbol: 'yUSDOCT20',
      decimals: 18,
    },
    {
      chainId: 1,
      address: '0x208d174775dc39fe18b1b374972f77ddec6c0f73',
      name: 'uUSDrBTC Synthetic Expiring 1 Oct 2020',
      symbol: 'uUSDrBTC-OCT',
      decimals: 18,
    },
    {
      chainId: 1,
      address: '0xf06ddacf71e2992e2122a1a0168c6967afdf63ce',
      name: 'uUSDrBTC Synthetic Expiring 31 Dec 2020',
      symbol: 'uUSDrBTC-DEC',
      decimals: 18,
    },
    {
      chainId: 1,
      address: '0xd16c79c8a39d44b2f3eb45d2019cd6a42b03e2a9',
      name: 'uUSDwETH Synthetic Expiring 31 Dec 2020',
      symbol: 'uUSDwETH-DEC',
      decimals: 18,
    },
    {
      chainId: 1,
      address: '0x3d995510f8d82c2ea341845932b5ddde0bead9a3',
      name: 'uGAS-JAN21 Token Expiring 31 Jan 2021',
      symbol: 'uGAS-JAN21',
      decimals: 18,
    },
    {
      chainId: 1,
      address: '0x90f802c7e8fb5d40b0de583e34c065a3bd2020d8',
      name: 'YD-ETH-MAR21 Token Expiring 31 Mar 2021',
      symbol: 'YD-ETH-MAR21',
      decimals: 18,
    },
    {
      chainId: 1,
      address: '0x002f0b1a71c5730cf2f4da1970a889207bdb6d0d',
      name: 'YD-BTC-MAR21 Token Expiring 31 Mar 2021',
      symbol: 'YD-BTC-MAR21',
      decimals: 18,
    },
    {
      chainId: 1,
      address: '0x1062ad0e59fa67fa0b27369113098cc941dd0d5f',
      name: 'UMA 35 Call Expirying 30 Apr 2021',
      symbol: 'UMAc35-0421',
      decimals: 18,
    },
    {
      chainId: 1,
      address: '0xf93340b1a3adf7eedcaec25fae8171d4b736e89f',
      name: 'pxUSD Synthetic USD Expiring 1 April 2021',
      symbol: 'pxUSD_MAR2021',
      decimals: 18,
    },
    {
      chainId: 1,
      address: '0x84bd083b1c8bf929f39c98bc17cf518f40154f58',
      name: 'Mario Cash Synthetic Token Expiring 15 January 2021',
      symbol: 'Mario Cash-JAN-2021',
      decimals: 18,
    },
    {
      chainId: 1,
      address: '0x81fab276aec924fbde190cf379783526d413cf70',
      name: 'uGAS-FEB21 Token Expiring 28 Feb 2021',
      symbol: 'uGAS-FEB21',
      decimals: 18,
    },
    {
      chainId: 1,
      address: '0x4e110603e70b0b5f1c403ee543b37e1f1244cf28',
      name: 'uGAS-MAR21 Token Expiring 31 Mar 2021',
      symbol: 'uGAS-MAR21',
      decimals: 18,
    },
    {
      chainId: 1,
      address: '0xcf55a7f92d5e0c6683debbc1fc20c0a6e056df13',
      name: 'Zelda Elastic Cash',
      symbol: 'Zelda Elastic Cash',
      decimals: 18,
    },
    {
      chainId: 1,
      address: '0x654eebac62240e6c56bab5f6adf7cfa74a894510',
      name: 'Zelda Spring Nuts Cash',
      symbol: 'Zelda Spring Nuts Cash',
      decimals: 18,
    },
    {
      chainId: 1,
      address: '0xa48920cc1ad85d8ea13af5d7be180c0338c306dd',
      name: 'Zelda Summer Nuts Cash',
      symbol: 'Zelda Summer Nuts Cash',
      decimals: 18,
    },
    {
      chainId: 1,
      address: '0x249a198d59b57fda5dda90630febc86fd8c7594c',
      name: 'Zelda Whirlwind Cash',
      symbol: 'Zelda Whirlwind Cash',
      decimals: 18,
    },
    {
      chainId: 1,
      address: '0x5ed1406873c9eb91f6f9a67ac4e152387c1132e7',
      name: 'Zelda Reinforced Cash',
      symbol: 'Zelda Reinforced Cash',
      decimals: 18,
    },
    {
      chainId: 1,
      address: '0x8104c9f13118320eefe5fbea8a44d600b85981ef',
      name: 'Mini Mario Summer Cash',
      symbol: 'Mini Mario Summer Cash',
      decimals: 18,
    },
    {
      chainId: 1,
      address: '0x69746c719e59674b147df25f50e7cfa0673cb625',
      name: 'Mini Mario Spring Cash',
      symbol: 'Mini Mario Spring Cash',
      decimals: 18,
    },
    {
      chainId: 1,
      address: '0x6b1257641d18791141f025eab36fb567c4b564ff',
      name: 'Bitcoin Dominance Token 31 March 2021',
      symbol: 'BTCDOM-MAR2021',
      decimals: 18,
    },
    {
      chainId: 1,
      address: '0x4e83b6287588a96321b2661c5e041845ff7814af',
      name: 'Altcoin Dominance Token 31 March 2021',
      symbol: 'ALTDOM-MAR2021',
      decimals: 18,
    },
    {
      chainId: 1,
      address: '0x59fec83ec709c893aedd1a144cf1828eb04127cd',
      name: 'pxGOLD Synthetic GOLD Expiring 31 May 2021',
      symbol: 'pxGOLD_MAY2021',
      decimals: 18,
    },
    {
      chainId: 1,
      address: '0x89337BFb7938804c3776C9FB921EccAf5ab76758',
      name: 'Compound Annualized Rate Future Expiring 28 March 2021',
      symbol: 'CAR-USDC-MAR21',
      decimals: 18,
    },
    {
      chainId: 1,
      address: '0xec58d3aefc9aaa2e0036fa65f70d569f49d9d1ed',
      name: 'uSTONKS Index Token April 2021',
      symbol: 'uSTONKS_APR21',
      decimals: 6,
    },
    {
      chainId: 1,
      address: '0xa6B9d7E3d76cF23549293Fb22c488E0Ea591A44e',
      name: 'uGAS-JUN21 Token Expiring 30 Jun 2021',
      symbol: 'uGAS-JUN21',
      decimals: 18,
    },
    {
      chainId: 1,
      address: '0xe813b65da6c38a04591aed3f082d32db7d53c382',
      name: 'Yield Dollar [WETH Dec 2021]',
      symbol: 'YD-ETH-DEC21',
      decimals: 18,
    },
    {
      chainId: 1,
      address: '0x4b606e9eb2228c70f44453afe5a73e1fea258ce1',
      name: 'pxUSD Synthetic USD Expiring 31 Mar 2022',
      symbol: 'pxUSD_MAR2022',
      decimals: 18,
    },
    {
      chainId: 1,
      address: '0x5247c0db4044fb6f97f32c7e1b48758019a5a912',
      name: 'pxGOLD Synthetic Gold Expiring 31 Mar 2022',
      symbol: 'pxGOLD_MAR2022',
      decimals: 18,
    },
    {
      chainId: 1,
      address: '0x56fb1acaff95c0b6ebcd17c8361a63d98b1a5a11',
      name: 'uForex CNYUSD Synthetic Token April 2021',
      symbol: 'uCNYUSD-APR',
      decimals: 6,
    },
    {
      chainId: 1,
      address: '0xd49fa405dce086c65d66ca1ca41f8e98583812b4',
      name: 'uForex EURUSD Synthetic Token April 2021',
      symbol: 'uEURUSD-APR',
      decimals: 6,
    },
    {
      chainId: 1,
      address: '0x29dddacba3b231ee8d673dd0f0fa759ea145561b',
      name: 'DEFI_PULSE_TOTAL_TVL Synthetic Token Expiring 15 April 2021',
      symbol: 'TVL_ALL_APRIL15',
      decimals: 6,
    },
    {
      chainId: 1,
      address: '0xcbe430927370e95b4b10cfc702c6017ec7abefc3',
      name: 'Yield Dollar [WETH Jun 2021]',
      symbol: 'YD-ETH-JUN21',
      decimals: 18,
    },
    {
      chainId: 1,
      address: '0x4b7fb448df91c8ed973494f8c8c4f12daf3a8521',
      name: 'Yield Dollar [renBTC Jun 2021]',
      symbol: 'YD-BTC-JUN21',
      decimals: 8,
    },
    {
      chainId: 1,
      address: '0x3108c33b6fb38efedaefd8b5f7ca01d5f5c7372d',
      name: 'Yield Dollar UMA 21',
      symbol: 'yUMA21',
      decimals: 18,
    },
    {
      chainId: 1,
      address: '0x0cae9e4d663793c2a2A0b211c1Cf4bBca2B9cAa7',
      name: 'Mirrored Amazon',
      symbol: 'MAMZN',
      decimals: 18,
    },
    {
      chainId: 1,
      address: '0x31c63146a635EB7465e5853020b39713AC356991',
      name: 'M US Oil',
      symbol: 'MUSO',
      decimals: 18,
    },
    {
      chainId: 1,
      address: '0x59A921Db27Dd6d4d974745B7FfC5c33932653442',
      name: 'M Google',
      symbol: 'MGOOGL',
      decimals: 18,
    },
    {
      chainId: 1,
      address: '0xf72FCd9DCF0190923Fadd44811E240Ef4533fc86',
      name: 'Mirrored ProShares',
      symbol: 'MVIXY',
      decimals: 18,
    },
    {
      chainId: 1,
      address: '0x56aA298a19C93c6801FDde870fA63EF75Cc0aF72',
      name: 'Mirrored Alibaba',
      symbol: 'MBABA',
      decimals: 18,
    },
    {
      chainId: 1,
      address: '0x0e99cC0535BB6251F6679Fa6E65d6d3b430e840B',
      name: 'Mirrored Facebook',
      symbol: 'MFB',
      decimals: 18,
    },
    {
      chainId: 1,
      address: '0x13B02c8dE71680e71F0820c996E4bE43c2F57d15',
      name: 'Mirrored Invesco QQ',
      symbol: 'MQQQ',
      decimals: 18,
    },
    {
      chainId: 1,
      address: '0x41BbEDd7286dAab5910a1f15d12CBda839852BD7',
      name: 'Mirrored Microsoft',
      symbol: 'MMSFT',
      decimals: 18,
    },
    {
      chainId: 1,
      address: '0x9d1555d8cB3C846Bb4f7D5B1B1080872c3166676',
      name: 'Mirrored iShares Si',
      symbol: 'MSLV',
      decimals: 18,
    },
    {
      chainId: 1,
      address: '0x21cA39943E91d704678F5D00b6616650F066fD63',
      name: 'Mirrored Tesla',
      symbol: 'MTSLA',
      decimals: 18,
    },
    {
      chainId: 1,
      address: '0xe82bbB62fA81d0701643d1675FB50ec52fD3Df92',
      name: 'DYDX Token',
      symbol: 'DYDX',
      decimals: 18,
    },
    {
      chainId: 10,
      address: '0xE405de8F52ba7559f9df3C368500B6E6ae6Cee49',
      name: 'sETH',
      symbol: 'Synth Ether',
      decimals: 18,
    },
    {
      chainId: 10,
      address: '0x298B9B95708152ff6968aafd889c6586e9169f1D',
      name: 'sBTC',
      symbol: 'Synth Bitcoin',
      decimals: 18,
    },
    {
      chainId: 10,
      address: '0xc5Db22719A06418028A40A9B5E9A7c02959D0d08',
      name: 'sLINK',
      symbol: 'Synth Link',
      decimals: 18,
    },
    {
      chainId: 1,
      address: '0x6B60eE11b73230045cF9095E6e43AE9Ea638e172',
      name: 'Shatner',
      symbol: 'SHAT',
      decimals: 18,
    },
    {
      chainId: 1,
      address: '0x7277a44D1325D81Ac58893002a1B40a41bea43fe',
      name: 'FAANG Index',
      symbol: 'FAANG',
      decimals: 18,
    },
    {
      chainId: 1,
      address: '0x76175599887730786bdA1545D0D7AcE8737fEBB1',
      name: 'ENS DAO',
      symbol: 'ENS',
      decimals: 18,
    },
  ],
}
