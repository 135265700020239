import { darkTheme, getDefaultWallets, RainbowKitProvider } from '@rainbow-me/rainbowkit'
import { sendAnalyticsEvent, user } from '@uniswap/analytics'
import { CustomUserProperties, InterfaceEventName, WalletConnectionResult } from '@uniswap/analytics-events'
import { getWalletMeta } from '@uniswap/conedison/provider/meta'
import { getConnection } from 'connection'
import { isSupportedChain } from 'constants/chains'
import { RPC_PROVIDERS } from 'constants/providers'
import { TraceJsonRpcVariant, useTraceJsonRpcFlag } from 'featureFlags/flags/traceJsonRpc'
import { useEthersProvider } from 'hooks/useEthersProvider'
import usePrevious from 'hooks/usePrevious'
import { ReactNode, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useConnectedWallets } from 'state/wallets/hooks'
import { getCurrentPageFromLocation } from 'utils/urlRoutes'
import { Chain, configureChains, createConfig, useAccount, useNetwork, WagmiConfig } from 'wagmi'
import { publicProvider } from 'wagmi/providers/public'

import { MANTLE_CHAIN_ID, MANTLE_CONTRACTS } from '../../constants/newChains/mantle'
import { MANTLE_TESTNET_CONTRACTS } from '../../constants/newChains/mantleTestnet'
import { MANTLE_TESTNET_CHAIN_ID } from '../../smart-order-router'

const mantle_testnet = {
  id: MANTLE_TESTNET_CHAIN_ID,
  name: 'Mantle Testnet',
  network: 'mantle_testnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Mantle',
    symbol: 'MNT',
  },
  rpcUrls: {
    public: { http: ['https://rpc.sepolia.mantle.xyz/'] },
    default: { http: ['https://rpc.sepolia.mantle.xyz/'] },
  },
  blockExplorers: {
    default: { name: 'SnowTrace', url: 'https://explorer.sepolia.mantle.xyz/' },
  },
  contracts: {
    multicall3: {
      address: MANTLE_TESTNET_CONTRACTS.ButterInterfaceMulticall,
      blockCreated: 14662916,
    },
  },
  iconUrl: 'https://www.mantle.xyz/logo-light.svg',
} as Chain

const mantle = {
  id: MANTLE_CHAIN_ID,
  name: 'Mantle',
  network: 'mantle',
  nativeCurrency: {
    decimals: 18,
    name: 'Mantle',
    symbol: 'MNT',
  },
  rpcUrls: {
    public: { http: ['https://rpc.mantle.xyz/'] },
    default: { http: ['https://rpc.mantle.xyz/'] },
  },
  blockExplorers: {
    default: { name: 'Mantle Explorer', url: 'https://explorer.mantle.xyz/' },
  },
  contracts: {
    multicall3: {
      address: MANTLE_CONTRACTS.ButterInterfaceMulticall,
      blockCreated: 22966160,
    },
  },
  iconUrl: 'https://swap.butter.xyz/mantle-network-icon.png',
}

const { chains, publicClient } = configureChains([mantle, mantle_testnet], [publicProvider()])

const { connectors } = getDefaultWallets({
  appName: 'Butter.xyz',
  projectId: '8544fc1d05681a92b6f7eab571976631',
  chains,
})

const wagmiConfig = createConfig({
  autoConnect: true,
  connectors,
  publicClient,
})

export default function Web3Provider({ children }: { children: ReactNode }) {
  // useEagerlyConnect()
  // const connections = useOrderedConnections()
  // const connectors: [Connector, Web3ReactHooks][] = connections.map(({ hooks, connector }) => [connector, hooks])

  // // Force a re-render when our connection state changes.
  // const [index, setIndex] = useState(0)
  // useEffect(() => setIndex((index) => index + 1), [connections])
  // const key = useMemo(
  //   () => connections.map((connection) => connection.getName()).join('-') + index,
  //   [connections, index]
  // )

  return (
    // <Web3ReactProvider connectors={connectors} key={key}>
    <WagmiConfig config={wagmiConfig}>
      <RainbowKitProvider
        chains={chains}
        theme={darkTheme({
          accentColor: 'rgb(249, 251, 101)',
          accentColorForeground: '#313131',
        })}
      >
        <Updater />
        {children}
      </RainbowKitProvider>
    </WagmiConfig>
    // </Web3ReactProvider>
  )
}

/** A component to run hooks under the Web3ReactProvider context. */
function Updater() {
  // const { account, chainId, connector, provider } = useWeb3React()
  const account = useAccount()?.address
  const connector: any = useAccount()?.connector
  const chainId = useNetwork().chain?.id
  const provider = useEthersProvider()
  const { pathname } = useLocation()
  const currentPage = getCurrentPageFromLocation(pathname)

  // Trace RPC calls (for debugging).
  const networkProvider = isSupportedChain(chainId) ? RPC_PROVIDERS[chainId] : undefined
  const shouldTrace = useTraceJsonRpcFlag() === TraceJsonRpcVariant.Enabled
  useEffect(() => {
    if (shouldTrace) {
      provider?.on('debug', trace)
      if (provider !== networkProvider) {
        networkProvider?.on('debug', trace)
      }
    }
    return () => {
      provider?.off('debug', trace)
      networkProvider?.off('debug', trace)
    }
  }, [networkProvider, provider, shouldTrace])

  // Send analytics events when the active account changes.
  const previousAccount = usePrevious(account)
  const [connectedWallets, addConnectedWallet] = useConnectedWallets()
  useEffect(() => {
    if (account && account !== previousAccount && connector) {
      const walletType = getConnection(connector).getName()
      const peerWalletAgent = provider ? getWalletMeta(provider)?.agent : undefined
      const isReconnect = connectedWallets.some(
        (wallet) => wallet.account === account && wallet.walletType === walletType
      )

      // User properties *must* be set before sending corresponding event properties,
      // so that the event contains the correct and up-to-date user properties.
      user.set(CustomUserProperties.WALLET_ADDRESS, account)
      user.set(CustomUserProperties.WALLET_TYPE, walletType)
      user.set(CustomUserProperties.PEER_WALLET_AGENT, peerWalletAgent ?? '')
      if (chainId) {
        user.postInsert(CustomUserProperties.ALL_WALLET_CHAIN_IDS, chainId)
      }
      user.postInsert(CustomUserProperties.ALL_WALLET_ADDRESSES_CONNECTED, account)

      sendAnalyticsEvent(InterfaceEventName.WALLET_CONNECT_TXN_COMPLETED, {
        result: WalletConnectionResult.SUCCEEDED,
        wallet_address: account,
        wallet_type: walletType,
        is_reconnect: isReconnect,
        peer_wallet_agent: peerWalletAgent,
        page: currentPage,
      })

      addConnectedWallet({ account, walletType })
    }
  }, [account, addConnectedWallet, currentPage, chainId, connectedWallets, connector, previousAccount, provider])

  return null
}

function trace(event: any) {
  if (!event?.request) return
  const { method, id, params } = event.request
  console.groupCollapsed(method, id)
  console.debug(params)
  console.groupEnd()
}
