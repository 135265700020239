import { Trans } from '@lingui/macro'
import { FeeAmount } from '@uniswap/v3-sdk'
import { useFeeTierDistribution } from 'hooks/useFeeTierDistribution'
import { PoolState } from 'hooks/usePools'
import React from 'react'

export function FeeTierPercentageBadge({
  feeAmount,
  distributions,
  poolState,
}: {
  feeAmount: FeeAmount
  distributions: ReturnType<typeof useFeeTierDistribution>['distributions']
  poolState: PoolState
}) {
  return (
    <div className="inline rounded-xl bg-section text-xs font-bold px-2">
      {!distributions || poolState === PoolState.NOT_EXISTS || poolState === PoolState.INVALID ? (
        <Trans>Not created</Trans>
      ) : distributions[feeAmount] !== undefined ? (
        <Trans>{distributions[feeAmount]?.toFixed(0)}% select</Trans>
      ) : (
        <Trans>No data</Trans>
      )}
    </div>
  )
}
