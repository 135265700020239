import { Trans } from '@lingui/macro'
import { Currency } from '@uniswap/sdk-core'
import classnames from 'classnames'

// the order of displayed base currencies from left to right is always in sort order
// currencyA is treated as the preferred base currency
export default function RateToggle({
  currencyA,
  currencyB,
  handleRateToggle,
}: {
  currencyA: Currency
  currencyB: Currency
  handleRateToggle: () => void
}) {
  const tokenA = currencyA?.wrapped
  const tokenB = currencyB?.wrapped

  const isSorted = tokenA && tokenB && tokenA.sortsBefore(tokenB)

  return tokenA && tokenB ? (
    <button
      className="inline-flex items-center p-1 opacity-50 bg-section rounded-full"
      onClick={handleRateToggle}
    >
      <div
        className={classnames('text-xs font-bold px-2 py-[2px] rounded-full ', {
          'bg-section text-primary px-2 py-[2px]': isSorted,
        })}
      >
        <Trans>{isSorted ? currencyA.symbol : currencyB.symbol}</Trans>
      </div>
      <div
        className={classnames('text-xs font-bold px-2 py-[2px] rounded-full ', {
          'bg-section text-primary ': !isSorted,
        })}
      >
        <Trans>{isSorted ? currencyB.symbol : currencyA.symbol}</Trans>
      </div>
    </button>
  ) : null
}
