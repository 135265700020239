import { Trans } from '@lingui/macro'
import { Percent } from '@uniswap/sdk-core'
import { useTheme } from 'styled-components/macro'
import formatPriceImpact from 'utils/formatPriceImpact'

import { ThemedText } from '../../theme'
import { AutoColumn } from '../Column'
import { RowBetween, RowFixed } from '../Row'
import { MouseoverTooltip } from '../Tooltip'

interface PriceImpactWarningProps {
  priceImpact: Percent
}

export default function PriceImpactWarning({ priceImpact }: PriceImpactWarningProps) {
  const theme = useTheme()

  return (
    <div className="py-3 px-4 border border-error rounded-xl bg-base-100">
      <AutoColumn gap="sm">
        <MouseoverTooltip
          text={
            <Trans>
              A swap of this size may have a high price impact, given the current liquidity in the pool. There may be a
              large difference between the amount of your input token and what you will receive in the output token
            </Trans>
          }
        >
          <RowBetween>
            <RowFixed>
              <ThemedText.DeprecatedSubHeader color={theme.accentFailure}>
                <Trans>Price impact warning</Trans>
              </ThemedText.DeprecatedSubHeader>
            </RowFixed>
            <div className="text-error">{formatPriceImpact(priceImpact)}</div>
          </RowBetween>
        </MouseoverTooltip>
      </AutoColumn>
    </div>
  )
}
