import { Trans } from '@lingui/macro'
import { ConnectButton } from '@rainbow-me/rainbowkit'
import { sendAnalyticsEvent, Trace, TraceEvent, useTrace } from '@uniswap/analytics'
import {
  BrowserEvent,
  InterfaceElementName,
  InterfaceEventName,
  InterfacePageName,
  InterfaceSectionName,
  SharedEventName,
  SwapEventName,
} from '@uniswap/analytics-events'
import { formatCurrencyAmount, NumberType } from '@uniswap/conedison/format'
import { ChainId, Currency, CurrencyAmount, Fraction, Percent, Token } from '@uniswap/sdk-core'
import classnames from 'classnames'
import { useToggleAccountDrawer } from 'components/AccountDrawer'
import { sendEvent } from 'components/analytics'
import PriceImpactModal from 'components/swap/PriceImpactModal'
import PriceImpactWarning from 'components/swap/PriceImpactWarning'
import SwapDetailsDropdown from 'components/swap/SwapDetailsDropdown'
import TokenSafetyModal from 'components/TokenSafety/TokenSafetyModal'
import { asSupportedChain, isSupportedChain } from 'constants/chains'
import useENSAddress from 'hooks/useENSAddress'
import { useMaxAmountIn } from 'hooks/useMaxAmountIn'
import usePermit2Allowance, { AllowanceState } from 'hooks/usePermit2Allowance'
import usePrevious from 'hooks/usePrevious'
import { useSwapCallback } from 'hooks/useSwapCallback'
import { useUSDPrice } from 'hooks/useUSDPrice'
import JSBI from 'jsbi'
import { useNativeCurrencyBalances } from 'lib/hooks/useCurrencyBalance'
import { formatEventPropertiesForTrade } from 'lib/utils/analytics'
import { ROUTER_ADDRESS } from 'lib/utils/router'
import { ReactNode, useCallback, useEffect, useMemo, useReducer, useState } from 'react'
import { ArrowDown } from 'react-feather'
import { useQuery } from 'react-query'
import { useLocation, useNavigate } from 'react-router-dom'
import { InterfaceTrade, TradeState } from 'state/routing/types'
import styled, { useTheme } from 'styled-components/macro'
import { didUserReject } from 'utils/swapErrorToUserReadableMessage'
import { useAccount, useNetwork, useSwitchNetwork } from 'wagmi'

import butterxmantle from '../../assets/images/butter-x-mantle.png'
// import fishingrod from '../../assets/images/fishing-rod-100.gif'
import AddressInputPanel from '../../components/AddressInputPanel'
import { AutoColumn } from '../../components/Column'
import SwapCurrencyInputPanel from '../../components/CurrencyInputPanel/SwapCurrencyInputPanel'
import { Button } from '../../components/daisy/Button'
import { AutoRow } from '../../components/Row'
import confirmPriceImpactWithoutFee from '../../components/swap/confirmPriceImpactWithoutFee'
import ConfirmSwapModal from '../../components/swap/ConfirmSwapModal'
import { ArrowWrapper, WidePageWrapper } from '../../components/swap/styleds'
import SwapHeader from '../../components/swap/SwapHeader'
import { getSwapCurrencyId, TOKEN_SHORTHANDS } from '../../constants/tokens'
import { useCurrency, useDefaultActiveTokens } from '../../hooks/Tokens'
import { useIsSwapUnsupported } from '../../hooks/useIsSwapUnsupported'
import useWrapCallback, { WrapErrorText, WrapType } from '../../hooks/useWrapCallback'
// import { useButterFishing } from '../../nft/utils/butterFishing'
import { Field, replaceSwapState } from '../../state/swap/actions'
import { useDefaultsFromURLSearch, useDerivedSwapInfo, useSwapActionHandlers } from '../../state/swap/hooks'
import swapReducer, { initialState as initialSwapState, SwapState } from '../../state/swap/reducer'
import { LinkStyledButton, ThemedText } from '../../theme'
import { computeFiatValuePriceImpact } from '../../utils/computeFiatValuePriceImpact'
import { maxAmountSpend } from '../../utils/maxAmountSpend'
import { computeRealizedPriceImpact, warningSeverity } from '../../utils/prices'

// export const ArrowContainer = styled.div`
//   display: inline-flex;
//   align-items: center;
//   justify-content: center;

//   width: 100%;
//   height: 100%;
// `

export const SwitchPairButton = ({ onClick }: { onClick?: () => void }) => {
  return (
    <button
      className="inline-flex items-center justify-center p-2 bg-section-solid border border-section rounded-xl"
      onClick={onClick}
    >
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.5">
          <path
            d="M2.33996 5.99839C2.67807 5.04292 3.25271 4.18867 4.01027 3.51536C4.76783 2.84204 5.6836 2.3716 6.67215 2.14793C7.6607 1.92426 8.6898 1.95465 9.66342 2.23627C10.637 2.51789 11.5235 3.04156 12.24 3.75841L15.3333 6.66505M0.666626 9.3317L3.75996 12.2383C4.47646 12.9552 5.36287 13.4789 6.3365 13.7605C7.31012 14.0421 8.33922 14.0725 9.32777 13.8488C10.3163 13.6252 11.2321 13.1547 11.9896 12.4814C12.7472 11.8081 13.3218 10.9538 13.66 9.99836"
            stroke="currentColor"
            strokeWidth="1.33333"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M15.3334 2.66602V6.66519H11.3334"
            stroke="currentColor"
            strokeWidth="1.33333"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M0.666626 13.3302V9.33105H4.66663"
            stroke="currentColor"
            strokeWidth="1.33333"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </svg>
    </button>
  )
}

// background-color: ${({ theme }) => theme.backgroundModule};
// const SwapSection = styled.div`
//   position: relative;

//   border-radius: 12px;
//   padding: 16px;
//   color: ${({ theme }) => theme.textSecondary};
//   font-size: 14px;
//   line-height: 20px;
//   font-weight: 500;

//   &:before {
//     box-sizing: border-box;
//     background-size: 100%;
//     border-radius: inherit;

//     position: absolute;
//     top: 0;
//     left: 0;

//     width: 100%;
//     height: 100%;
//     pointer-events: none;
//     content: '';
//     border: 1px solid ${({ theme }) => theme.backgroundModule};
//   }

//   &:hover:before {
//     border-color: ${({ theme }) => theme.stateOverlayHover};
//   }

//   &:focus-within:before {
//     border-color: ${({ theme }) => theme.stateOverlayPressed};
//   }
// `

const SwapSection = ({ children }: { children: ReactNode }) => {
  return <div className="border border-section bg-section rounded-xl p-4">{children}</div>
}

const OutputSwapSection = styled(SwapSection)`
  border-bottom: ${({ theme }) => `1px solid ${theme.backgroundSurface}`};
`

function getIsValidSwapQuote(
  trade: InterfaceTrade | undefined,
  tradeState: TradeState,
  swapInputError?: ReactNode
): boolean {
  return Boolean(swapInputError && trade && tradeState === TradeState.VALID)
}

function largerPercentValue(a?: Percent, b?: Percent) {
  if (a && b) {
    return a.greaterThan(b) ? a : b
  } else if (a) {
    return a
  } else if (b) {
    return b
  }
  return undefined
}

const TRADE_STRING = 'SwapRouter'

export default function SwapPage({ className }: { className?: string }) {
  const connectedChainId = useNetwork().chain?.id
  const loadedUrlParams = useDefaultsFromURLSearch()

  const location = useLocation()

  return (
    <Trace page={InterfacePageName.SWAP_PAGE} shouldLogImpression>
      <WidePageWrapper>
        <Swap
          className={className}
          chainId={connectedChainId}
          prefilledState={{
            [Field.INPUT]: { currencyId: loadedUrlParams?.[Field.INPUT]?.currencyId },
            [Field.OUTPUT]: { currencyId: loadedUrlParams?.[Field.OUTPUT]?.currencyId },
          }}
        />
      </WidePageWrapper>
    </Trace>
  )
}

/**
 * The swap component displays the swap interface, manages state for the swap, and triggers onchain swaps.
 *
 * In most cases, chainId should refer to the connected chain, i.e. ` useNetwork().chain?.id`.
 * However if this component is being used in a context that displays information from a different, unconnected
 * chain (e.g. the TDP), then chainId should refer to the unconnected chain.
 */
export function Swap({
  className,
  prefilledState = {},
  chainId,
  onCurrencyChange,
  disableTokenInputs = false,
}: {
  className?: string
  prefilledState?: Partial<SwapState>
  chainId?: ChainId
  onCurrencyChange?: (selected: Pick<SwapState, Field.INPUT | Field.OUTPUT>) => void
  disableTokenInputs?: boolean
}) {
  const [showAnnouncement, setShowAnnouncement] = useState<boolean>(false)

  const account = useAccount()?.address
  const connectedChainId = useNetwork().chain?.id
  const unsupportedChain = useNetwork().chain?.unsupported
  const { isLoading: switchingChain } = useSwitchNetwork()
  const trace = useTrace()

  const { data: l1Gas } = useQuery({
    queryKey: ['l1GasPrice'],
    queryFn: async () => {
      const response = await fetch('https://cloudflare-eth.com/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          jsonrpc: '2.0',
          id: 4,
          method: 'eth_gasPrice',
        }),
      })
      if (!response.ok) {
        throw new Error('Failed to fetch gas price')
      }
      return parseInt((await response.json()).result)
    },
    refetchInterval: 10 * 60 * 1000,
  })

  // token warning stuff
  const prefilledInputCurrency = useCurrency(prefilledState?.[Field.INPUT]?.currencyId)
  const prefilledOutputCurrency = useCurrency(prefilledState?.[Field.OUTPUT]?.currencyId)

  const nativeBalances = useNativeCurrencyBalances([account])
  const nativeBalance = nativeBalances[account ?? '']

  const [loadedInputCurrency, setLoadedInputCurrency] = useState(prefilledInputCurrency)
  const [loadedOutputCurrency, setLoadedOutputCurrency] = useState(prefilledOutputCurrency)

  useEffect(() => {
    setLoadedInputCurrency(prefilledInputCurrency)
    setLoadedOutputCurrency(prefilledOutputCurrency)
  }, [prefilledInputCurrency, prefilledOutputCurrency])

  const [dismissTokenWarning, setDismissTokenWarning] = useState<boolean>(false)
  const [showPriceImpactModal, setShowPriceImpactModal] = useState<boolean>(false)

  const urlLoadedTokens: Token[] = useMemo(
    () => [loadedInputCurrency, loadedOutputCurrency]?.filter((c): c is Token => c?.isToken ?? false) ?? [],
    [loadedInputCurrency, loadedOutputCurrency]
  )
  const handleConfirmTokenWarning = useCallback(() => {
    setDismissTokenWarning(true)
  }, [])

  // dismiss warning if all imported tokens are in active lists
  const defaultTokens = useDefaultActiveTokens(chainId)
  const importTokensNotInDefault = useMemo(
    () =>
      urlLoadedTokens &&
      urlLoadedTokens
        .filter((token: Token) => {
          return !(token.address in defaultTokens)
        })
        .filter((token: Token) => {
          // Any token addresses that are loaded from the shorthands map do not need to show the import URL
          const supported = asSupportedChain(chainId)
          if (!supported) return true
          return !Object.keys(TOKEN_SHORTHANDS).some((shorthand) => {
            const shorthandTokenAddress = TOKEN_SHORTHANDS[shorthand][supported]
            return shorthandTokenAddress && shorthandTokenAddress === token.address
          })
        }),
    [chainId, defaultTokens, urlLoadedTokens]
  )

  const theme = useTheme()

  // toggle wallet when disconnected
  const toggleWalletDrawer = useToggleAccountDrawer()

  // swap state
  const [state, dispatch] = useReducer(swapReducer, { ...initialSwapState, ...prefilledState })
  const { typedValue, recipient, independentField } = state

  const previousConnectedChainId = usePrevious(connectedChainId)
  const previousPrefilledState = usePrevious(prefilledState)
  useEffect(() => {
    const combinedInitialState = { ...initialSwapState, ...prefilledState }
    const chainChanged = previousConnectedChainId && previousConnectedChainId !== connectedChainId
    const prefilledInputChanged =
      previousPrefilledState &&
      previousPrefilledState?.[Field.INPUT]?.currencyId !== prefilledState?.[Field.INPUT]?.currencyId
    const prefilledOutputChanged =
      previousPrefilledState &&
      previousPrefilledState?.[Field.OUTPUT]?.currencyId !== prefilledState?.[Field.OUTPUT]?.currencyId
    if (chainChanged || prefilledInputChanged || prefilledOutputChanged) {
      dispatch(
        replaceSwapState({
          ...initialSwapState,
          ...prefilledState,
          field: combinedInitialState.independentField ?? Field.INPUT,
          inputCurrencyId: combinedInitialState.INPUT.currencyId ?? undefined,
          outputCurrencyId: combinedInitialState.OUTPUT.currencyId ?? undefined,
        })
      )
      // reset local state
      setSwapState({
        tradeToConfirm: undefined,
        swapError: undefined,
        showConfirm: false,
        txHash: undefined,
      })
    }
  }, [connectedChainId, prefilledState, previousConnectedChainId, previousPrefilledState])

  const {
    trade: { state: tradeState, trade, method },
    allowedSlippage,
    autoSlippage,
    currencyBalances,
    parsedAmount,
    currencies,
    inputError: swapInputError,
  } = useDerivedSwapInfo(state, chainId)

  const {
    wrapType,
    execute: onWrap,
    inputError: wrapInputError,
  } = useWrapCallback(currencies[Field.INPUT], currencies[Field.OUTPUT], typedValue)
  const showWrap: boolean = wrapType !== WrapType.NOT_APPLICABLE
  const { address: recipientAddress } = useENSAddress(recipient)

  const parsedAmounts = useMemo(
    () =>
      showWrap
        ? {
            [Field.INPUT]: parsedAmount,
            [Field.OUTPUT]: parsedAmount,
          }
        : {
            [Field.INPUT]: independentField === Field.INPUT ? parsedAmount : trade?.inputAmount,
            [Field.OUTPUT]: independentField === Field.OUTPUT ? parsedAmount : trade?.outputAmount,
          },
    [independentField, parsedAmount, showWrap, trade]
  )

  const fiatValueInput = useUSDPrice(parsedAmounts[Field.INPUT])
  const fiatValueOutput = useUSDPrice(parsedAmounts[Field.OUTPUT])
  const showFiatValueInput = Boolean(parsedAmounts[Field.INPUT])
  const showFiatValueOutput = Boolean(parsedAmounts[Field.OUTPUT])

  const [routeNotFound, routeIsLoading, routeIsSyncing] = useMemo(
    () => [!trade?.swaps, TradeState.LOADING === tradeState, TradeState.LOADING === tradeState && Boolean(trade)],
    [trade, tradeState]
  )

  const fiatValueTradeInput = useUSDPrice(trade?.inputAmount)
  const fiatValueTradeOutput = useUSDPrice(trade?.outputAmount)
  const stablecoinPriceImpact = useMemo(
    () =>
      routeIsSyncing || !trade
        ? undefined
        : computeFiatValuePriceImpact(fiatValueTradeInput.data, fiatValueTradeOutput.data),
    [fiatValueTradeInput, fiatValueTradeOutput, routeIsSyncing, trade]
  )

  const { onSwitchTokens, onCurrencySelection, onUserInput, onChangeRecipient } = useSwapActionHandlers(dispatch)
  const isValid = !swapInputError
  const dependentField: Field = independentField === Field.INPUT ? Field.OUTPUT : Field.INPUT

  const handleTypeInput = useCallback(
    (value: string) => {
      onUserInput(Field.INPUT, value)
    },
    [onUserInput]
  )
  const handleTypeOutput = useCallback(
    (value: string) => {
      onUserInput(Field.OUTPUT, value)
    },
    [onUserInput]
  )

  const navigate = useNavigate()
  const swapIsUnsupported = useIsSwapUnsupported(currencies[Field.INPUT], currencies[Field.OUTPUT])

  // reset if they close warning without tokens in params
  const handleDismissTokenWarning = useCallback(() => {
    setDismissTokenWarning(true)
    navigate('/swap/')
  }, [navigate])

  // modal and loading
  const [{ showConfirm, tradeToConfirm, swapError, txHash }, setSwapState] = useState<{
    showConfirm: boolean
    tradeToConfirm?: InterfaceTrade
    swapError?: Error
    txHash?: string
  }>({
    showConfirm: false,
    tradeToConfirm: undefined,
    swapError: undefined,
    txHash: undefined,
  })

  const formattedAmounts = useMemo(
    () => ({
      [independentField]: typedValue,
      [dependentField]: showWrap
        ? parsedAmounts[independentField]?.toExact() ?? ''
        : formatCurrencyAmount(parsedAmounts[dependentField], NumberType.SwapTradeAmount, ''),
    }),
    [dependentField, independentField, parsedAmounts, showWrap, typedValue]
  )

  const userHasSpecifiedInputOutput = Boolean(
    currencies[Field.INPUT] && currencies[Field.OUTPUT] && parsedAmounts[independentField]?.greaterThan(JSBI.BigInt(0))
  )

  const maximumAmountIn = useMaxAmountIn(trade, allowedSlippage)
  const allowance = usePermit2Allowance(
    maximumAmountIn ??
      (parsedAmounts[Field.INPUT]?.currency.isToken
        ? (parsedAmounts[Field.INPUT] as CurrencyAmount<Token>)
        : undefined),
    // isSupportedChain(chainId) ? UNIVERSAL_ROUTER_ADDRESS(chainId) : undefined
    isSupportedChain(chainId) ? ROUTER_ADDRESS(chainId) : undefined
  )

  const maxInputAmount: CurrencyAmount<Currency> | undefined = useMemo(
    () => maxAmountSpend(currencyBalances[Field.INPUT]),
    [currencyBalances]
  )
  const showPercentageButton = Boolean(
    maxInputAmount?.greaterThan(0) && !parsedAmounts[Field.INPUT]?.equalTo(maxInputAmount)
  )
  const swapFiatValues = useMemo(() => {
    return { amountIn: fiatValueTradeInput.data, amountOut: fiatValueTradeOutput.data }
  }, [fiatValueTradeInput, fiatValueTradeOutput])

  // the callback to execute the swap
  const { callback: swapCallback } = useSwapCallback(
    trade,
    swapFiatValues,
    allowedSlippage,
    allowance.state === AllowanceState.ALLOWED ? allowance.permitSignature : undefined
  )

  const handleContinueToReview = useCallback(() => {
    setSwapState({
      tradeToConfirm: trade,
      swapError: undefined,
      showConfirm: true,
      txHash: undefined,
    })
  }, [trade])

  const handleSwap = useCallback(() => {
    if (!swapCallback) {
      return
    }
    if (stablecoinPriceImpact && !confirmPriceImpactWithoutFee(stablecoinPriceImpact)) {
      return
    }
    setSwapState((currentState) => ({
      ...currentState,
      swapError: undefined,
      txHash: undefined,
    }))
    swapCallback()
      .then((hash) => {
        setSwapState((currentState) => ({
          ...currentState,
          swapError: undefined,
          txHash: hash,
        }))
        sendEvent({
          category: 'Swap',
          action: 'transaction hash',
          label: hash,
        })
        sendEvent({
          category: 'Swap',
          action:
            recipient === null
              ? 'Swap w/o Send'
              : (recipientAddress ?? recipient) === account
              ? 'Swap w/o Send + recipient'
              : 'Swap w/ Send',
          label: [TRADE_STRING, trade?.inputAmount?.currency?.symbol, trade?.outputAmount?.currency?.symbol, 'MH'].join(
            '/'
          ),
        })
      })
      .catch((error) => {
        if (!didUserReject(error)) {
          sendAnalyticsEvent(SwapEventName.SWAP_ERROR, {
            confirmedTrade: tradeToConfirm,
          })
        }
        setSwapState((currentState) => ({
          ...currentState,
          swapError: error,
          txHash: undefined,
        }))
      })
  }, [
    swapCallback,
    stablecoinPriceImpact,
    recipient,
    recipientAddress,
    account,
    trade?.inputAmount?.currency?.symbol,
    trade?.outputAmount?.currency?.symbol,
    tradeToConfirm,
  ])

  // errors
  const [swapQuoteReceivedDate, setSwapQuoteReceivedDate] = useState<Date | undefined>()

  // warnings on the greater of fiat value price impact and execution price impact
  const { priceImpactSeverity, largerPriceImpact } = useMemo(() => {
    const marketPriceImpact = trade?.priceImpact ? computeRealizedPriceImpact(trade) : undefined
    const largerPriceImpact = largerPercentValue(marketPriceImpact, stablecoinPriceImpact)
    return { priceImpactSeverity: warningSeverity(largerPriceImpact), largerPriceImpact }
  }, [stablecoinPriceImpact, trade])

  const handleConfirmDismiss = useCallback(() => {
    setSwapState((currentState) => ({ ...currentState, showConfirm: false }))
    // if there was a tx hash, we want to clear the input
    if (txHash) {
      onUserInput(Field.INPUT, '')
    }
  }, [onUserInput, txHash])

  const handleAcceptChanges = useCallback(() => {
    setSwapState((currentState) => ({ ...currentState, tradeToConfirm: trade }))
  }, [trade])

  const handleInputSelect = useCallback(
    (inputCurrency: Currency) => {
      onCurrencySelection(Field.INPUT, inputCurrency)
      onCurrencyChange?.({
        [Field.INPUT]: {
          currencyId: getSwapCurrencyId(inputCurrency),
        },
        [Field.OUTPUT]: state[Field.OUTPUT],
      })
    },
    [onCurrencyChange, onCurrencySelection, state]
  )

  const handleMaxInput = useCallback(() => {
    maxInputAmount && onUserInput(Field.INPUT, maxInputAmount.toExact())
    sendEvent({
      category: 'Swap',
      action: 'Max',
    })
  }, [maxInputAmount, onUserInput])

  const handlePercentageInput = useCallback(
    (percentage: Fraction) => {
      maxInputAmount && onUserInput(Field.INPUT, maxInputAmount.multiply(percentage).toExact())
      sendEvent({
        category: 'Swap',
        action: 'Percentage',
      })
    },
    [maxInputAmount, onUserInput]
  )

  const handleOutputSelect = useCallback(
    (outputCurrency: Currency) => {
      onCurrencySelection(Field.OUTPUT, outputCurrency)
      onCurrencyChange?.({
        [Field.INPUT]: state[Field.INPUT],
        [Field.OUTPUT]: {
          currencyId: getSwapCurrencyId(outputCurrency),
        },
      })
    },
    [onCurrencyChange, onCurrencySelection, state]
  )

  const showPriceImpactWarning = largerPriceImpact && priceImpactSeverity > 3

  const prevTrade = usePrevious(trade)
  useEffect(() => {
    if (!trade || prevTrade === trade) return // no new swap quote to log

    setSwapQuoteReceivedDate(new Date())
    sendAnalyticsEvent(SwapEventName.SWAP_QUOTE_RECEIVED, {
      ...formatEventPropertiesForTrade(trade, allowedSlippage, trade.gasUseEstimateUSD ?? undefined, method),
      ...trace,
    })
  }, [prevTrade, trade, trace, allowedSlippage, method])

  const showDetailsDropdown = Boolean(
    !showWrap && userHasSpecifiedInputOutput && (trade || routeIsLoading || routeIsSyncing)
  )

  const { data: accountStatus } = useQuery(
    ['account-status', account],
    ({ queryKey }) => {
      const address = queryKey[1]
      if (!address) return null
      return fetch(`${process.env.REACT_APP_BUTTER_API_URL}/v1/rewards/status?address=${address}`)
        .then((res) => {
          if (res.status !== 200) {
            throw new Error('Failed to fetch account status')
          }
          return res.json()
        })
        .then((data) => {
          return data.status as 'active' | 'awaiting_terms' | 'awaiting_activation'
        })
    },
    {
      refetchInterval: 60 * 1000,
    }
  )

  // const fishingData = useButterFishing(account, !!accountStatus)

  const gasUse = parseFloat(trade?.gasUseWei || '0') / 10 ** 18
  const rollupFee = Math.round((((l1Gas || 0) / 10 ** 18) * 0.8) / 100)

  const totalNativeTokenRequired = trade?.swaps[0].inputAmount.currency.isNative
    ? parseFloat(formattedAmounts[Field.INPUT]) + gasUse + rollupFee
    : gasUse + rollupFee

  const networkFeeNotCovered =
    gasUse > 0 && (l1Gas || 0) > 0 && nativeBalance && parseFloat(nativeBalance.toExact()) < totalNativeTokenRequired

  return (
    <div id="swap-page">
      {showAnnouncement && (
        <div className="flex w-full justify-center">
          <div className="inline-flex flex-col sm:flex-row bg-off-black rounded-xl gap-2 md:gap-4 px-4 py-3">
            {/*{fishingData?.total > 0 ? (*/}
            {/*  <div className="flex gap-2">*/}
            {/*    /!*<div className="shrink-0">*!/*/}
            {/*    /!*  <img src={fishingrod} className="h-6 mx-auto" />*!/*/}
            {/*    /!*</div>*!/*/}
            {/*    /!*<div>*!/*/}
            {/*    /!*  <a href="https://butter.xyz/fishing" target="_blank" rel="noreferrer">*!/*/}
            {/*    /!*    You&apos;ve earned {fishingData.total} fishing attempt{fishingData.total > 1 ? 's' : null}.*!/*/}
            {/*    /!*  </a>*!/*/}
            {/*    /!*</div>*!/*/}
            {/*  </div>*/}
            {/*) : (*/}
              <>
                <div className="shrink-0">
                  <img src={butterxmantle} className="h-6 mx-auto" />
                </div>
                <div>
                  <a
                    target="_blank"
                    className="text-white flex gap-1 items-center"
                    href="https://twitter.com/butterexchange/status/1734906597762035812"
                    rel="noreferrer"
                  >
                    <div>Sweet $MNT rewards, smooth exchanges</div>
                  </a>
                </div>
              </>
            {/*)}*/}
            <div className="border-r border-section" />
            <button className="text-hint" onClick={() => setShowAnnouncement(false)}>
              Dismiss
            </button>
          </div>
        </div>
      )}
      <div className="relative mx-auto mt-10" style={{ maxWidth: '480px' }}>
        <div className="underglow absolute inset-0"></div>
        <div className={classnames('relative bg-section-solid rounded-xl p-6', className)}>
          <TokenSafetyModal
            isOpen={importTokensNotInDefault.length > 0 && !dismissTokenWarning}
            tokenAddress={importTokensNotInDefault[0]?.address}
            secondTokenAddress={importTokensNotInDefault[1]?.address}
            onContinue={handleConfirmTokenWarning}
            onCancel={handleDismissTokenWarning}
            showCancel={true}
          />
          <SwapHeader autoSlippage={autoSlippage} chainId={chainId} />
          {trade && showConfirm && (
            <ConfirmSwapModal
              accountStatus={accountStatus || null}
              trade={trade}
              originalTrade={tradeToConfirm}
              onAcceptChanges={handleAcceptChanges}
              txHash={txHash}
              allowedSlippage={allowedSlippage}
              onConfirm={handleSwap}
              allowance={allowance}
              swapError={swapError}
              onDismiss={handleConfirmDismiss}
              swapQuoteReceivedDate={swapQuoteReceivedDate}
              fiatValueInput={fiatValueTradeInput}
              fiatValueOutput={fiatValueTradeOutput}
            />
          )}
          {showPriceImpactModal && showPriceImpactWarning && (
            <PriceImpactModal
              priceImpact={largerPriceImpact}
              onDismiss={() => setShowPriceImpactModal(false)}
              onContinue={() => {
                setShowPriceImpactModal(false)
                handleContinueToReview()
              }}
            />
          )}

          <div style={{ display: 'relative' }}>
            <SwapSection>
              <Trace section={InterfaceSectionName.CURRENCY_INPUT_PANEL}>
                <SwapCurrencyInputPanel
                  label={
                    independentField === Field.OUTPUT && !showWrap ? <Trans>From (at most)</Trans> : <Trans>From</Trans>
                  }
                  disabled={disableTokenInputs}
                  value={formattedAmounts[Field.INPUT]}
                  showPercentageButton={showPercentageButton}
                  currency={currencies[Field.INPUT] ?? null}
                  onUserInput={handleTypeInput}
                  onMax={handleMaxInput}
                  onPercentage={handlePercentageInput}
                  fiatValue={showFiatValueInput ? fiatValueInput : undefined}
                  onCurrencySelect={handleInputSelect}
                  otherCurrency={currencies[Field.OUTPUT]}
                  showCommonBases
                  id={InterfaceSectionName.CURRENCY_INPUT_PANEL}
                  loading={independentField === Field.OUTPUT && routeIsSyncing}
                />
              </Trace>
            </SwapSection>
            <div className="-my-[14px] z-[2] relative flex justify-center">
              <TraceEvent
                events={[BrowserEvent.onClick]}
                name={SwapEventName.SWAP_TOKENS_REVERSED}
                element={InterfaceElementName.SWAP_TOKENS_REVERSE_ARROW_BUTTON}
              >
                <SwitchPairButton
                  onClick={() => {
                    !disableTokenInputs && onSwitchTokens()
                  }}
                />
              </TraceEvent>
            </div>
          </div>
          <AutoColumn gap="xs">
            <div>
              <OutputSwapSection>
                <Trace section={InterfaceSectionName.CURRENCY_OUTPUT_PANEL}>
                  <SwapCurrencyInputPanel
                    value={formattedAmounts[Field.OUTPUT]}
                    disabled={disableTokenInputs}
                    onUserInput={handleTypeOutput}
                    label={
                      independentField === Field.INPUT && !showWrap ? <Trans>To (at least)</Trans> : <Trans>To</Trans>
                    }
                    showPercentageButton={false}
                    hideBalance={false}
                    fiatValue={showFiatValueOutput ? fiatValueOutput : undefined}
                    priceImpact={stablecoinPriceImpact}
                    currency={currencies[Field.OUTPUT] ?? null}
                    onCurrencySelect={handleOutputSelect}
                    otherCurrency={currencies[Field.INPUT]}
                    showCommonBases
                    id={InterfaceSectionName.CURRENCY_OUTPUT_PANEL}
                    loading={independentField === Field.INPUT && routeIsSyncing}
                  />
                </Trace>
                {recipient !== null && !showWrap ? (
                  <>
                    <AutoRow justify="space-between" style={{ padding: '0 1rem' }}>
                      <ArrowWrapper clickable={false}>
                        <ArrowDown size="16" color={theme.textSecondary} />
                      </ArrowWrapper>
                      <LinkStyledButton id="remove-recipient-button" onClick={() => onChangeRecipient(null)}>
                        <Trans>- Remove recipient</Trans>
                      </LinkStyledButton>
                    </AutoRow>
                    <AddressInputPanel id="recipient" value={recipient} onChange={onChangeRecipient} />
                  </>
                ) : null}
              </OutputSwapSection>
            </div>
            {showDetailsDropdown && (
              <SwapDetailsDropdown
                trade={trade}
                syncing={routeIsSyncing}
                loading={routeIsLoading}
                allowedSlippage={allowedSlippage}
              />
            )}
            {showPriceImpactWarning && <PriceImpactWarning priceImpact={largerPriceImpact} />}
            <div className="mt-4">
              {swapIsUnsupported ? (
                <Button color="primary" size={['btn-lg']} block="btn-block" disabled={true}>
                  <ThemedText.DeprecatedMain mb="4px">
                    <Trans>Unsupported Asset</Trans>
                  </ThemedText.DeprecatedMain>
                </Button>
              ) : switchingChain ? (
                <Button color="primary" size={['btn-lg']} block="btn-block" disabled={true}>
                  <Trans>Connecting to new network</Trans>
                </Button>
              ) : !account ? (
                <TraceEvent
                  events={[BrowserEvent.onClick]}
                  name={InterfaceEventName.CONNECT_WALLET_BUTTON_CLICKED}
                  properties={{ received_swap_quote: getIsValidSwapQuote(trade, tradeState, swapInputError) }}
                  element={InterfaceElementName.CONNECT_WALLET_BUTTON}
                >
                  <ConnectButton.Custom>
                    {({ openConnectModal }) => {
                      return (
                        <Button color="primary" size={['btn-lg']} block="btn-block" onClick={() => openConnectModal()}>
                          Connect Wallet
                        </Button>
                      )
                    }}
                  </ConnectButton.Custom>
                </TraceEvent>
              ) : unsupportedChain ? (
                <ConnectButton.Custom>
                  {({ openChainModal }) => {
                    return (
                      <Button color="error" size={['btn-lg']} block="btn-block" onClick={() => openChainModal()}>
                        Switch to Mantle
                      </Button>
                    )
                  }}
                </ConnectButton.Custom>
              ) : showWrap ? (
                <Button
                  color="primary"
                  size={['btn-lg']}
                  block="btn-block"
                  disabled={Boolean(wrapInputError)}
                  onClick={onWrap}
                >
                  {wrapInputError ? (
                    <WrapErrorText wrapInputError={wrapInputError} />
                  ) : wrapType === WrapType.WRAP ? (
                    <Trans>Wrap</Trans>
                  ) : wrapType === WrapType.UNWRAP ? (
                    <Trans>Unwrap</Trans>
                  ) : null}
                </Button>
              ) : routeNotFound && userHasSpecifiedInputOutput && !routeIsLoading && !routeIsSyncing ? (
                <Button color="neutral" size={['btn-lg']} block="btn-block" disabled={true}>
                  <Trans>Insufficient liquidity for this trade.</Trans>
                </Button>
              ) : (
                <TraceEvent
                  events={[BrowserEvent.onClick]}
                  name={SharedEventName.ELEMENT_CLICKED}
                  element={InterfaceElementName.SWAP_BUTTON}
                >
                  <Button
                    onClick={() => {
                      showPriceImpactWarning ? setShowPriceImpactModal(true) : handleContinueToReview()
                    }}
                    id="swap-button"
                    block="btn-block"
                    size={['btn-lg']}
                    disabled={!isValid || routeIsSyncing || routeIsLoading || networkFeeNotCovered}
                    color={
                      (isValid && priceImpactSeverity > 2 && allowance.state === AllowanceState.ALLOWED) ||
                      networkFeeNotCovered
                        ? 'error'
                        : 'primary'
                    }
                  >
                    {swapInputError ? (
                      swapInputError
                    ) : routeIsSyncing || routeIsLoading ? (
                      <Trans>Swap</Trans>
                    ) : priceImpactSeverity > 2 ? (
                      <Trans>Swap Anyway</Trans>
                    ) : networkFeeNotCovered ? (
                      <Trans>Not Enough $MNT for Network Fee</Trans>
                    ) : (
                      <Trans>Swap</Trans>
                    )}
                  </Button>
                </TraceEvent>
              )}
            </div>
          </AutoColumn>
        </div>
      </div>
    </div>
  )
}
