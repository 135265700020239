import { Trans } from '@lingui/macro'
import { formatCurrencyAmount, NumberType } from '@uniswap/conedison/format'
import { Currency, CurrencyAmount, Fraction, Percent } from '@uniswap/sdk-core'
import { Pair } from '@uniswap/v2-sdk'
import classnames from 'classnames'
import { AutoColumn } from 'components/Column'
import { LoadingOpacityContainer, loadingOpacityMixin } from 'components/Loader/styled'
import CurrencyLogo from 'components/Logo/CurrencyLogo'
import { isSupportedChain } from 'constants/chains'
import { ReactNode, useCallback, useState } from 'react'
import { Lock } from 'react-feather'
import styled, { useTheme } from 'styled-components/macro'
import { flexColumnNoWrap, flexRowNoWrap } from 'theme/styles'
import { useAccount, useNetwork } from 'wagmi'

import { ReactComponent as DropDown } from '../../assets/images/dropdown.svg'
import { useCurrencyBalance } from '../../state/connection/hooks'
import { ThemedText } from '../../theme'
import DoubleCurrencyLogo from '../DoubleLogo'
import { Input as NumericalInput } from '../NumericalInput'
import { RowBetween, RowFixed } from '../Row'
import CurrencySearchModal from '../SearchModal/CurrencySearchModal'
import { FiatValue } from './FiatValue'

const WalletIcon = ({ className }: { className?: string }) => (
  <svg className={className} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.1875 5.0625H3.9375C3.78832 5.0625 3.64524 5.00324 3.53975 4.89775C3.43426 4.79226 3.375 4.64918 3.375 4.5C3.375 4.35082 3.43426 4.20774 3.53975 4.10225C3.64524 3.99676 3.78832 3.9375 3.9375 3.9375H13.5C13.6492 3.9375 13.7923 3.87824 13.8977 3.77275C14.0032 3.66726 14.0625 3.52418 14.0625 3.375C14.0625 3.22582 14.0032 3.08274 13.8977 2.97725C13.7923 2.87176 13.6492 2.8125 13.5 2.8125H3.9375C3.48995 2.8125 3.06072 2.99029 2.74426 3.30676C2.42779 3.62322 2.25 4.05245 2.25 4.5V13.5C2.25 13.9476 2.42779 14.3768 2.74426 14.6932C3.06072 15.0097 3.48995 15.1875 3.9375 15.1875H15.1875C15.4859 15.1875 15.772 15.069 15.983 14.858C16.194 14.647 16.3125 14.3609 16.3125 14.0625V6.1875C16.3125 5.88913 16.194 5.60298 15.983 5.392C15.772 5.18103 15.4859 5.0625 15.1875 5.0625ZM12.6562 10.6875C12.4894 10.6875 12.3262 10.638 12.1875 10.5453C12.0487 10.4526 11.9406 10.3208 11.8767 10.1666C11.8129 10.0125 11.7962 9.84281 11.8287 9.67914C11.8613 9.51547 11.9416 9.36513 12.0596 9.24713C12.1776 9.12913 12.328 9.04877 12.4916 9.01621C12.6553 8.98366 12.825 9.00037 12.9791 9.06423C13.1333 9.12809 13.2651 9.23623 13.3578 9.37499C13.4505 9.51374 13.5 9.67687 13.5 9.84375C13.5 10.0675 13.4111 10.2821 13.2529 10.4404C13.0946 10.5986 12.88 10.6875 12.6562 10.6875Z"
      fill="currentColor"
    />
  </svg>
)

const InputPanel = styled.div<{ hideInput?: boolean }>`
  ${flexColumnNoWrap};
  position: relative;
  border-radius: 12px;
  z-index: 1;
  width: ${({ hideInput }) => (hideInput ? '100%' : 'initial')};
  transition: height 1s ease;
  will-change: height;
`

const FixedContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
`

const Container = styled.div<{ hideInput: boolean }>`
  min-height: 44px;
  border-radius: ${({ hideInput }) => (hideInput ? '16px' : '20px')};
  width: ${({ hideInput }) => (hideInput ? '100%' : 'initial')};
`

// const CurrencySelect = styled(ButtonGray)<{
//   visible: boolean
//   selected: boolean
//   hideInput?: boolean
//   disabled?: boolean
// }>`
//   align-items: center;
//   background-color: ${({ selected, theme }) => (selected ? theme.backgroundInteractive : theme.accentAction)};
//   opacity: ${({ disabled }) => (!disabled ? 1 : 0.4)};
//   box-shadow: ${({ selected }) => (selected ? 'none' : '0px 6px 10px rgba(0, 0, 0, 0.075)')};
//   color: ${({ selected, theme }) => (selected ? theme.textPrimary : theme.white)};
//   cursor: pointer;
//   height: unset;
//   border-radius: 16px;
//   outline: none;
//   user-select: none;
//   border: none;
//   font-size: 24px;
//   font-weight: 400;
//   width: ${({ hideInput }) => (hideInput ? '100%' : 'initial')};
//   padding: ${({ selected }) => (selected ? '4px 8px 4px 4px' : '6px 6px 6px 8px')};
//   gap: 8px;
//   justify-content: space-between;
//   margin-left: ${({ hideInput }) => (hideInput ? '0' : '12px')};

//   &:hover,
//   &:active {
//     background-color: ${({ theme, selected }) => (selected ? theme.backgroundInteractive : theme.accentAction)};
//   }

//   &:before {
//     background-size: 100%;
//     border-radius: inherit;

//     position: absolute;
//     top: 0;
//     left: 0;

//     width: 100%;
//     height: 100%;
//     content: '';
//   }

//   &:hover:before {
//     background-color: ${({ theme }) => theme.stateOverlayHover};
//   }

//   &:active:before {
//     background-color: ${({ theme }) => theme.stateOverlayPressed};
//   }

//   visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
// `

const CurrencySelect = ({
  visible,
  selected,
  hideInput,
  disabled,
  className,
  onClick,
  children,
}: {
  visible: boolean
  selected: boolean
  hideInput?: boolean
  disabled?: boolean
  children: ReactNode
  className?: string
  onClick: () => void
}) => {
  return (
    <button
      className={classnames(
        'bg-section border border-section rounded-full py-1 px-2 font-bold',
        {
          'opacity-50': disabled,
          hidden: !visible,
          'ml-3': !hideInput,
        },
        className
      )}
      onClick={onClick}
    >
      {children}
    </button>
  )
}

const PercentageSelect = ({ onClick, children }: { onClick: () => void; children: ReactNode }) => {
  return (
    <button
      className="rounded-xl text-center border border-section py-1 px-2 text-base-content translucent-text cursor-pointer hover:border-white active:opaque-text"
      onClick={onClick}
    >
      {children}
    </button>
  )
}

const InputRow = styled.div`
  ${flexRowNoWrap};
  align-items: center;
  justify-content: space-between;
`

const LabelRow = styled.div`
  ${flexRowNoWrap};
  align-items: center;
  font-size: 0.75rem;
  line-height: 1rem;

  span:hover {
    cursor: pointer;
  }
`

const FiatRow = styled(LabelRow)`
  justify-content: flex-end;
  min-height: 28px;
  padding: 8px 0px 0px 0px;
`

const Aligner = styled.span`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`

const StyledDropDown = styled(DropDown)<{ selected: boolean }>`
  margin: 0 0.25rem 0 0.35rem;
  height: 35%;
  margin-left: 8px;

  path {
    stroke: ${({ selected, theme }) => (selected ? theme.textPrimary : theme.white)};
    stroke-width: 2px;
  }
`

const StyledTokenName = styled.span<{ active?: boolean }>`
  ${({ active }) => (active ? '  margin: 0 0.25rem 0 0.25rem;' : '  margin: 0 0.25rem 0 0.25rem;')}
  font-size: 20px;
  font-weight: 600;
`

const StyledBalanceMax = styled.button<{ disabled?: boolean }>`
  background-color: transparent;
  border: none;
  color: ${({ theme }) => theme.accentAction};
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  opacity: ${({ disabled }) => (!disabled ? 1 : 0.4)};
  padding: 4px 6px;
  pointer-events: ${({ disabled }) => (!disabled ? 'initial' : 'none')};

  :hover {
    opacity: ${({ disabled }) => (!disabled ? 0.8 : 0.4)};
  }

  :focus {
    outline: none;
  }
`

const StyledNumericalInput = styled(NumericalInput)<{ $loading: boolean }>`
  ${loadingOpacityMixin};
  text-align: left;
  font-size: 36px;
  line-height: 44px;
  font-variant: small-caps;
`

interface SwapCurrencyInputPanelProps {
  value: string
  onUserInput: (value: string) => void
  onMax?: () => void
  onPercentage?: (percentage: Fraction) => void
  showPercentageButton: boolean
  label?: ReactNode
  onCurrencySelect?: (currency: Currency) => void
  currency?: Currency | null
  hideBalance?: boolean
  pair?: Pair | null
  hideInput?: boolean
  otherCurrency?: Currency | null
  fiatValue?: { data?: number; isLoading: boolean }
  priceImpact?: Percent
  id: string
  showCommonBases?: boolean
  showCurrencyAmount?: boolean
  disableNonToken?: boolean
  renderBalance?: (amount: CurrencyAmount<Currency>) => ReactNode
  locked?: boolean
  loading?: boolean
  disabled?: boolean
}

function TokenAddressCopy({ address }: { address?: string | null }) {
  const [copied, setCopied] = useState(false)
  return (
    <div
      className={classnames('ml-2 tooltip-bottom', {
        'tooltip tooltip-open': copied,
        invisible: !address,
      })}
      data-tip={copied ? 'Token address copied' : ''}
    >
      <button
        className="text-hint"
        onClick={() => {
          if (!address) return
          navigator.clipboard.writeText(address)
          setCopied(true)
          setTimeout(() => setCopied(false), 1500)
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-4 h-4"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75"
          />
        </svg>
      </button>
    </div>
  )
}

export default function SwapCurrencyInputPanel({
  value,
  onUserInput,
  onMax,
  onPercentage,
  showPercentageButton,
  onCurrencySelect,
  currency,
  otherCurrency,
  id,
  showCommonBases,
  showCurrencyAmount,
  disableNonToken,
  renderBalance,
  fiatValue,
  priceImpact,
  hideBalance = false,
  pair = null, // used for double token logo
  hideInput = false,
  locked = false,
  loading = false,
  disabled = false,
  ...rest
}: SwapCurrencyInputPanelProps) {
  const [modalOpen, setModalOpen] = useState(false)
  const account = useAccount()?.address
  const chainId = useNetwork().chain?.id
  const selectedCurrencyBalance = useCurrencyBalance(account ?? undefined, currency ?? undefined)
  const theme = useTheme()

  const handleDismissSearch = useCallback(() => {
    setModalOpen(false)
  }, [setModalOpen])

  const chainAllowed = isSupportedChain(chainId)

  return (
    <InputPanel id={id} hideInput={hideInput} {...rest}>
      {locked && (
        <FixedContainer>
          <AutoColumn gap="sm" justify="center">
            <Lock />
            <ThemedText.DeprecatedLabel fontSize="12px" textAlign="center" padding="0 12px">
              <Trans>The market price is outside your specified price range. Single-asset deposit only.</Trans>
            </ThemedText.DeprecatedLabel>
          </AutoColumn>
        </FixedContainer>
      )}
      <Container hideInput={hideInput}>
        <InputRow style={hideInput ? { padding: '0', borderRadius: '8px' } : {}}>
          {!hideInput && (
            <StyledNumericalInput
              className="token-amount-input"
              value={value}
              onUserInput={onUserInput}
              disabled={!chainAllowed || disabled}
              $loading={loading}
            />
          )}

          <TokenAddressCopy address={!currency?.isNative ? currency?.address : null} />
          <CurrencySelect
            disabled={!chainAllowed || disabled}
            visible={currency !== undefined}
            selected={!!currency}
            hideInput={hideInput}
            onClick={() => {
              if (onCurrencySelect) {
                setModalOpen(true)
              }
            }}
          >
            <Aligner>
              <RowFixed>
                {pair ? (
                  <span style={{ marginRight: '0.5rem' }}>
                    <DoubleCurrencyLogo currency0={pair.token0} currency1={pair.token1} size={24} margin={true} />
                  </span>
                ) : currency ? (
                  <CurrencyLogo style={{ marginRight: '0.5rem' }} currency={currency} size="24px" />
                ) : null}
                {pair ? (
                  <div className="font-bold">
                    {pair?.token0.symbol}:{pair?.token1.symbol}
                  </div>
                ) : (
                  <div className="font-bold">
                    {(currency && currency.symbol && currency.symbol.length > 20
                      ? currency.symbol.slice(0, 4) +
                        '...' +
                        currency.symbol.slice(currency.symbol.length - 5, currency.symbol.length)
                      : currency?.symbol) || (
                      <div className="pl-2">
                        <Trans>Select token</Trans>
                      </div>
                    )}
                  </div>
                )}
              </RowFixed>
              {onCurrencySelect && <StyledDropDown selected={!!currency} />}
            </Aligner>
          </CurrencySelect>
        </InputRow>
        {Boolean(!hideInput && !hideBalance) && (
          <div className="flex flex-end items-center text-hint text-xs mt-4">
            <RowBetween>
              <LoadingOpacityContainer $loading={loading}>
                {fiatValue && <FiatValue fiatValue={fiatValue} priceImpact={priceImpact} />}
              </LoadingOpacityContainer>
              {account ? (
                <div className="flex items-center gap-2">
                  <div>
                    {!hideBalance && currency && selectedCurrencyBalance ? (
                      renderBalance ? (
                        renderBalance(selectedCurrencyBalance)
                      ) : (
                        <div className="flex gap-2">
                          <WalletIcon /> {formatCurrencyAmount(selectedCurrencyBalance, NumberType.TokenNonTx)}
                        </div>
                      )
                    ) : null}
                  </div>
                  {/* {(showMaxButton && selectedCurrencyBalance || true) ? (
                    <TraceEvent
                      events={[BrowserEvent.onClick]}
                      name={SwapEventName.SWAP_MAX_TOKEN_AMOUNT_SELECTED}
                      element={InterfaceElementName.MAX_TOKEN_AMOUNT_BUTTON}
                    >
                      <button className="text-primary font-bold" onClick={onMax}>
                        <Trans>Max</Trans>
                      </button>
                    </TraceEvent>
                  ) : null} */}
                </div>
              ) : (
                <span />
              )}
            </RowBetween>
          </div>
        )}
        {Boolean(!hideInput && !hideBalance) && showPercentageButton && selectedCurrencyBalance && (
          <div className="grid grid-cols-4 gap-2 mt-4">
            <PercentageSelect onClick={() => onPercentage?.(new Fraction(1, 4))}>
              <Trans>25%</Trans>
            </PercentageSelect>
            <PercentageSelect onClick={() => onPercentage?.(new Fraction(1, 2))}>
              <Trans>50%</Trans>
            </PercentageSelect>
            <PercentageSelect onClick={() => onPercentage?.(new Fraction(3, 4))}>
              <Trans>75%</Trans>
            </PercentageSelect>
            <PercentageSelect onClick={() => onPercentage?.(new Fraction(1))}>
              <Trans>100%</Trans>
            </PercentageSelect>
          </div>
        )}
      </Container>
      {onCurrencySelect && (
        <CurrencySearchModal
          isOpen={modalOpen}
          onDismiss={handleDismissSearch}
          onCurrencySelect={onCurrencySelect}
          selectedCurrency={currency}
          otherSelectedCurrency={otherCurrency}
          showCommonBases={showCommonBases}
          showCurrencyAmount={showCurrencyAmount}
          disableNonToken={disableNonToken}
        />
      )}
    </InputPanel>
  )
}
