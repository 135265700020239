import { Trans } from '@lingui/macro'
import { Trace, TraceEvent } from '@uniswap/analytics'
import { BrowserEvent, InterfaceElementName, InterfaceEventName, InterfacePageName } from '@uniswap/analytics-events'
import { Token, V2_FACTORY_ADDRESSES } from '@uniswap/sdk-core'
import { useToggleAccountDrawer } from 'components/AccountDrawer'
import { ButtonGray, ButtonPrimary, ButtonText } from 'components/Button'
import { AutoColumn } from 'components/Column'
import { Button } from 'components/daisy/Button'
import { Menu } from 'components/Menu'
import PositionList from 'components/PositionList'
import { RowBetween, RowFixed } from 'components/Row'
import { isSupportedChain } from 'constants/chains'
import { useFilterPossiblyMaliciousPositions } from 'hooks/useFilterPossiblyMaliciousPositions'
import { useV3Positions } from 'hooks/useV3Positions'
import { useMemo, useState } from 'react'
import { AlertTriangle, BookOpen, ChevronsRight, Inbox, Layers, Plus } from 'react-feather'
import { Link } from 'react-router-dom'
import { useUserHideClosedPositions } from 'state/user/hooks'
import styled, { css, useTheme } from 'styled-components/macro'
import { ThemedText } from 'theme'
import { PositionDetails } from 'types/position'
import { useAccount, useNetwork } from 'wagmi'

import { LoadingRows } from './styleds'
import { useQuery } from 'react-query'
import { formatCurrencyAmount } from 'utils/formatCurrencyAmount'
import formatLocaleNumber from 'lib/utils/formatLocaleNumber'
import DoubleCurrencyLogo from 'components/DoubleLogo'
import classnames from 'classnames'
import { volumeFormatter } from 'nft/utils'

const PageWrapper = styled(AutoColumn)`
  padding: 68px 8px 0px;
  max-width: 870px;
  width: 100%;

  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToMedium`
    max-width: 800px;
  `};

  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToSmall`
    max-width: 500px;
  `};

  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.md}px`}) {
    padding-top: 48px;
  }

  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.sm}px`}) {
    padding-top: 20px;
  }
`
const TitleRow = styled(RowBetween)`
  color: ${({ theme }) => theme.textSecondary};
  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToSmall`
    flex-wrap: wrap;
    gap: 12px;
    width: 100%;
  `};
`
const ButtonRow = styled(RowFixed)`
  & > *:not(:last-child) {
    margin-left: 8px;
  }

  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToSmall`
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    flex-direction: row-reverse;
  `};
`
const PoolMenu = styled(Menu)`
  margin-left: 0;
  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToSmall`
    flex: 1 1 auto;
    width: 49%;
    right: 0px;
  `};

  a {
    width: 100%;
  }
`
const PoolMenuItem = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-weight: 500;
`
const MoreOptionsButton = styled(ButtonGray)`
  border-radius: 12px;
  flex: 1 1 auto;
  padding: 6px 8px;
  width: 100%;
  background-color: ${({ theme }) => theme.backgroundSurface};
  margin-right: 8px;
`

const MoreOptionsText = styled(ThemedText.DeprecatedBody)`
  align-items: center;
  display: flex;
`

const ErrorContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  max-width: 300px;
  min-height: 25vh;
`

const IconStyle = css`
  width: 48px;
  height: 48px;
  margin-bottom: 0.5rem;
`

const NetworkIcon = styled(AlertTriangle)`
  ${IconStyle}
`

const InboxIcon = styled(Inbox)`
  ${IconStyle}
`

const ResponsiveButtonPrimary = styled(ButtonPrimary)`
  border-radius: 12px;
  font-size: 16px;
  padding: 6px 8px;
  width: fit-content;
  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToSmall`
    flex: 1 1 auto;
    width: 100%;
  `};
`

// const MainContentWrapper = styled.main`
//   background-color: ${({ theme }) => theme.backgroundSurface};
//   border: 1px solid ${({ theme }) => theme.backgroundOutline};
//   padding: 0;
//   border-radius: 16px;
//   display: flex;
//   flex-direction: column;
//   box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
//     0px 24px 32px rgba(0, 0, 0, 0.01);
//   overflow: hidden;
// `

const MainContentWrapper = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="bg-section border border-section p-0 rounded-xl flex flex-col shadow overflow-hidden">
      {children}
    </div>
  )
}

function PositionsLoadingPlaceholder() {
  return (
    <LoadingRows>
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </LoadingRows>
  )
}

function WrongNetworkCard() {
  const theme = useTheme()
  const account = useAccount()?.address

  return (
    <>
      <PageWrapper>
        <AutoColumn gap="lg" justify="center">
          <AutoColumn gap="lg" style={{ width: '100%' }}>
            <TitleRow padding="0">
              <div className="font-bold text-4xl">
                <Trans>Pools</Trans>
              </div>
            </TitleRow>

            <MainContentWrapper>
              <ErrorContainer>
                <ThemedText.DeprecatedBody color={theme.textTertiary} textAlign="center">
                  <NetworkIcon className="mx-auto" strokeWidth={1.2} />
                  <div data-testid="pools-unsupported-err">
                    {!account && (
                      <Trans>Please connect your wallet.</Trans>
                    )}
                    {account && (
                      <Trans>Your connected network is unsupported.</Trans>
                    )}
                  </div>
                </ThemedText.DeprecatedBody>
              </ErrorContainer>
            </MainContentWrapper>
            <AllPools />
          </AutoColumn>
        </AutoColumn>
      </PageWrapper>
    </>
  )
}

function SortableHeader({
  sortKey,
  currentSortKey,
  setSortKey,
  label
}: {
  currentSortKey: string;
  sortKey: string;
  setSortKey: (key: string) => void;
  label: string
}) {
  return (
    <div className={classnames('py-4 px-4', {
      'cursor-pointer hover:text-white': sortKey !== currentSortKey,
      'cursor-default': sortKey === currentSortKey,
    })} onClick={() => setSortKey(sortKey)}>
      <div className="flex items-center gap-1">
        <div>{label}</div>
        <div className={classnames({
          'invisible': sortKey !== currentSortKey
        })}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-3 h-3">
            <path fillRule="evenodd" d="M12.53 16.28a.75.75 0 0 1-1.06 0l-7.5-7.5a.75.75 0 0 1 1.06-1.06L12 14.69l6.97-6.97a.75.75 0 1 1 1.06 1.06l-7.5 7.5Z" clipRule="evenodd" />
          </svg>
        </div>
      </div>
    </div>
  );
}

function AllPools() {
  const [currentSortKey, setSortKey] = useState('tvlUSD');
  const { data } = useQuery({
    queryKey: ['allPools'],
    queryFn: async () => {
      const response = await fetch('https://api-x.butter.xyz/v1/swap-pools', {
        method: 'GET',
      })
      if (!response.ok) {
        throw new Error('Failed to fetch gas price')
      }
      return await response.json()
    },
  });
  if (!data) return null;
  const sorted = data.slice().sort((a: any, b: any) => {
    return b[currentSortKey] - a[currentSortKey];
  });
  return (
    <MainContentWrapper>
      <div className="hidden md:block">
        <div className="font-bold uppercase">
          <div className="grid grid-cols-6 text-hint text-sm">
            <div className="py-4 px-4 col-span-2">Pool</div>
            <SortableHeader currentSortKey={currentSortKey} setSortKey={setSortKey} sortKey="volumeUSD" label="Volume" />
            <SortableHeader currentSortKey={currentSortKey} setSortKey={setSortKey} sortKey="tvlUSD" label="Liquidity" />
            <SortableHeader currentSortKey={currentSortKey} setSortKey={setSortKey} sortKey="feesUSD" label="Fees(24H)" />
            <SortableHeader currentSortKey={currentSortKey} setSortKey={setSortKey} sortKey="apy" label="APY(24H)" />
          </div>
        </div>
        <div>
          {sorted.map((poolData: any) => {
            return (
              <a target="_blank" href={`https://stats.butter.xyz/#/pools/${poolData.poolId}`} key={poolData.poolId} className="hover:bg-section grid grid-cols-6 border-t border-section py-4 text-sm">
                <div className="col-span-2 px-4">
                  <div className="flex items-center gap-4">
                    <DoubleCurrencyLogo
                      currency0={new Token(5000, poolData.pool.token1.id, 18, poolData.pool.token1.symbol)}
                      currency1={new Token(5000, poolData.pool.token0.id, 18, poolData.pool.token0.symbol)}
                      size={18}
                      margin
                    />
                    <div className="font-bold">
                      {poolData.pool.token0.symbol} - {poolData.pool.token1.symbol}
                    </div>
                    <div className="bg-section rounded-full text-xs py-1 px-2 font-bold">
                      {poolData.pool.feeTier / 10000}%
                    </div>
                  </div>
                </div>
                <div className="px-4">
                  ${volumeFormatter(poolData.volumeUSD)}
                </div>
                <div className="px-4">
                  ${volumeFormatter(poolData.tvlUSD)}
                </div>
                <div className="px-4">
                  ${volumeFormatter(poolData.feesUSD)}
                </div>
                <div className="px-4">
                  {(poolData.apy * 100).toFixed(2)}%
                </div>
              </a>
            );
          })}
        </div>
      </div>
      <div className="flex flex-col gap-4 md:hidden p-4">
        <select className="select select-bordered w-full bg-section" onChange={(e) => setSortKey(e.target.value)}>
          <option value="volumeUSD">Highest Volume</option>
          <option value="tvlUSD">Highest Liquidity</option>
          <option value="feesUSD">Highest Fees</option>
          <option value="apy">Highest APY</option>
        </select>
        {sorted.map((poolData: any) => {
          return (
            <a target="_blank" href={`https://stats.butter.xyz/#/pools/${poolData.poolId}`} key={poolData.poolId} className="block bg-section p-4 rounded-xl">
              <div>
                <div className="flex items-center gap-4">
                  <DoubleCurrencyLogo
                    currency0={new Token(5000, poolData.pool.token1.id, 18, poolData.pool.token1.symbol)}
                    currency1={new Token(5000, poolData.pool.token0.id, 18, poolData.pool.token0.symbol)}
                    size={18}
                    margin
                  />
                  <div className="font-bold">
                    {poolData.pool.token0.symbol} - {poolData.pool.token1.symbol}
                  </div>
                  <div className="bg-section rounded-full text-xs py-1 px-2 font-bold">
                    {poolData.pool.feeTier / 10000}%
                  </div>
                </div>
              </div>
              <div className="flex mt-4">
                <div className="w-1/4 text-sm">
                  <div className="text-hint mb-1 font-bold">Volume</div>
                  <div>
                    ${volumeFormatter(poolData.volumeUSD)}
                  </div>
                </div>
                <div className="w-1/4 text-sm">
                  <div className="text-hint mb-1 font-bold">Liquidity</div>
                  <div>
                    ${volumeFormatter(poolData.tvlUSD)}
                  </div>
                </div>
                <div className="w-1/4 text-sm">
                  <div className="text-hint mb-1 font-bold">Fees(24H)</div>
                  <div>
                    ${volumeFormatter(poolData.feesUSD)}
                  </div>
                </div>
                <div className="w-1/4 text-sm">
                  <div className="text-hint mb-1 font-bold">APY(24H)</div>
                  <div>
                    {(poolData.apy * 100).toFixed(2)}%
                  </div>
                </div>
              </div>
            </a>
          );
        })}
      </div>
    </MainContentWrapper>

  );

}

export default function Pool() {
  const account = useAccount()?.address
  const chainId = useNetwork().chain?.id
  const toggleWalletDrawer = useToggleAccountDrawer()

  const theme = useTheme()
  const [userHideClosedPositions, setUserHideClosedPositions] = useUserHideClosedPositions()

  const { positions, loading: positionsLoading } = useV3Positions(account)

  const [openPositions, closedPositions] = positions?.reduce<[PositionDetails[], PositionDetails[]]>(
    (acc, p) => {
      acc[p.liquidity?.isZero() ? 1 : 0].push(p)
      return acc
    },
    [[], []]
  ) ?? [[], []]

  const userSelectedPositionSet = useMemo(
    () => [...openPositions, ...(userHideClosedPositions ? [] : closedPositions)],
    [closedPositions, openPositions, userHideClosedPositions]
  )

  const filteredPositions = useFilterPossiblyMaliciousPositions(userSelectedPositionSet)

  if (!isSupportedChain(chainId)) {
    return <WrongNetworkCard />
  }

  const showConnectAWallet = Boolean(!account)
  const showV2Features = Boolean(V2_FACTORY_ADDRESSES[chainId])

  const menuItems = [
    {
      content: (
        <PoolMenuItem>
          <Trans>Migrate</Trans>
          <ChevronsRight size={16} />
        </PoolMenuItem>
      ),
      link: '/migrate/v2',
      external: false,
    },
    {
      content: (
        <PoolMenuItem>
          <Trans>V2 liquidity</Trans>
          <Layers size={16} />
        </PoolMenuItem>
      ),
      link: '/pools/v2',
      external: false,
    },
    {
      content: (
        <PoolMenuItem>
          <Trans>Learn</Trans>
          <BookOpen size={16} />
        </PoolMenuItem>
      ),
      link: 'https://support.uniswap.org/hc/en-us/categories/8122334631437-Providing-Liquidity-',
      external: true,
    },
  ]

  return (
    <Trace page={InterfacePageName.POOL_PAGE} shouldLogImpression>
      <PageWrapper>
        <AutoColumn gap="lg" justify="center">
          <AutoColumn gap="lg" style={{ width: '100%' }}>
            <TitleRow padding="0">
              <ThemedText.LargeHeader>
                <Trans>Pools</Trans>
              </ThemedText.LargeHeader>
              <ButtonRow>
                {/* {showV2Features && (
                  <PoolMenu
                    menuItems={menuItems}
                    flyoutAlignment={FlyoutAlignment.LEFT}
                    ToggleUI={(props: any) => (
                      <MoreOptionsButton {...props}>
                        <MoreOptionsText>
                          <Trans>More</Trans>
                          <ChevronDown size={15} />
                        </MoreOptionsText>
                      </MoreOptionsButton>
                    )}
                  />
                )} */}
                <Link to="/add">
                  <Button color="primary" tag="div">
                    <div className="flex items-center gap-2 font-bold">
                      <Plus size={16} />
                      <Trans>New Position</Trans>
                    </div>
                  </Button>
                </Link>
              </ButtonRow>
            </TitleRow>

            <MainContentWrapper>
              {positionsLoading ? (
                <PositionsLoadingPlaceholder />
              ) : filteredPositions && closedPositions && filteredPositions.length > 0 ? (
                <PositionList
                  positions={filteredPositions}
                  setUserHideClosedPositions={setUserHideClosedPositions}
                  userHideClosedPositions={userHideClosedPositions}
                />
              ) : (
                <ErrorContainer>
                  <ThemedText.DeprecatedBody color={theme.textTertiary} textAlign="center">
                    <InboxIcon className="mx-auto" strokeWidth={1} style={{ marginTop: '2em' }} />
                    <div>
                      <Trans>Your active liquidity positions will appear here.</Trans>
                    </div>
                  </ThemedText.DeprecatedBody>
                  {!showConnectAWallet && closedPositions.length > 0 && (
                    <ButtonText
                      style={{ marginTop: '.5rem' }}
                      onClick={() => setUserHideClosedPositions(!userHideClosedPositions)}
                    >
                      <Trans>Show closed positions</Trans>
                    </ButtonText>
                  )}
                  {showConnectAWallet && (
                    <TraceEvent
                      events={[BrowserEvent.onClick]}
                      name={InterfaceEventName.CONNECT_WALLET_BUTTON_CLICKED}
                      properties={{ received_swap_quote: false }}
                      element={InterfaceElementName.CONNECT_WALLET_BUTTON}
                    >
                      <ButtonPrimary
                        style={{ marginTop: '2em', marginBottom: '2em', padding: '8px 16px' }}
                        onClick={toggleWalletDrawer}
                      >
                        <Trans>Connect a wallet</Trans>
                      </ButtonPrimary>
                    </TraceEvent>
                  )}
                </ErrorContainer>
              )}
            </MainContentWrapper>
            <AllPools />
            {/* <HideSmall>
              <CTACards />
            </HideSmall> */}
          </AutoColumn>
        </AutoColumn>
      </PageWrapper>
    </Trace>
  )
}
